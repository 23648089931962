import {
  Box as MuiBox,
  Grid,
  styled,
  Typography,
  IconButton,
} from '@mui/material';
import React from 'react';
import { StudentCredentialInput } from '../../../shared/types/generated';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { Close, PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const Box = styled(MuiBox)(({ theme }) => ({
  display: 'flex-row',
  alignItems: 'center',
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingInline: theme.spacing(2),
  transition: theme.transitions.create(['border-color', 'box-shadow'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    borderColor: theme.palette.text.primary,
  },
}));

type StudentCredentialInfoProps = {
  studentCredential: StudentCredentialInput;
  onChange: (name: string, value: string | moment.Moment | null) => void;
  studentCredentialCardFile: File | null;
  onDrop: (file: File | null) => void;
};

export const StudentCredentialInfoTwo: React.FC<StudentCredentialInfoProps> = ({
  studentCredentialCardFile,
  onDrop,
}) => {
  const [hoverDropzone, setHoverDropzone] = React.useState(false);

  const renderFilePreview = (): JSX.Element => {
    if (studentCredentialCardFile) {
      const fileType = studentCredentialCardFile.type;

      if (fileType === 'application/pdf') {
        return (
          <MuiBox
            position="relative"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MuiBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={1.5}
            >
              <PictureAsPdfIcon fontSize="large" color="primary" />
            </MuiBox>
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              {studentCredentialCardFile.name}
            </Typography>
            <IconButton
              size="small"
              onClick={() => onDrop(null)}
              sx={{
                position: 'absolute',
                top: '-5%',
                right: '22%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </MuiBox>
        );
      } else if (fileType.startsWith('image/')) {
        return (
          <MuiBox
            position="relative"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={URL.createObjectURL(studentCredentialCardFile)}
              alt="Student Credential"
              style={{
                width: '50%',
                height: 'auto',
                borderRadius: '5px',
              }}
            />
            <IconButton
              size="small"
              onClick={() => onDrop(null)}
              sx={{
                position: 'absolute',
                top: '-5%',
                right: '22%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </MuiBox>
        );
      }
    }
    return <></>;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {studentCredentialCardFile ? (
          renderFilePreview()
        ) : (
          <Dropzone
            maxFiles={1}
            onError={(error) => console.log(error)}
            onDropAccepted={(files) => onDrop(files[0])}
            multiple={false}
            accept={{
              'image/*': ['.png', '.jpeg', '.jpg'],
              'application/pdf': ['.pdf'],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                component={'div'}
                sx={{ backgroundColor: '#F4F6F9' }}
                height={{ xs: '120px', md: '140px' }}
                width={'100%'}
                padding={'0px 0px !important'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                {...getRootProps()}
                onMouseEnter={() => setHoverDropzone(true)}
                onMouseLeave={() => setHoverDropzone(false)}
              >
                <input {...getInputProps()} />
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  padding={'0px 0px !important'}
                  border={'0px solid black !important'}
                >
                  <Box
                    component={'div'}
                    sx={{ backgroundColor: 'white' }}
                    border={'1px solid #EDEDED !important'}
                    borderRadius={'5px'}
                    width={'130px !important'}
                    height={'35px !important'}
                    padding={'0px 0px !important'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    marginBottom={'16px'}
                  >
                    <Typography
                      fontFamily={'mr-eaves-xl-modern'}
                      color={hoverDropzone ? PRIMARY_COLOR : 'black'}
                      fontWeight={700}
                      fontSize={'18px'}
                    >
                      {' '}
                      Subir archivo{' '}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  fontFamily={'mr-eaves-xl-modern'}
                  fontSize={15}
                  color="GrayText"
                >
                  Anexa tu credencial estudiantil
                </Typography>
                <Typography
                  fontFamily={'mr-eaves-xl-modern'}
                  color="GrayText"
                  fontSize={15}
                >
                  o docente en formato PNG o PDF.
                </Typography>
              </Box>
            )}
          </Dropzone>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography
          paddingTop={1}
          color={'#FF3C41'}
          fontSize={15}
          fontFamily={'mr-eaves-xl-modern'}
        >
          Requisito obligatorio.
        </Typography>
      </Grid>
    </Grid>
  );
};
