import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Plan } from '../../../shared/types/generated';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    chipOnTop: {
      width: '200px !important',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    chipOnTopText: {
      fontSize: '14px !important',
      lineHeight: '14px !important',
      padding: '7px 5px 5px 5px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px !important',
        lineHeight: '14px !important',
      },
    },
    secondText: {
      fontSize: '14px !important',
      lineHeight: '16px !important',
      color: '#606060',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important',
        lineHeight: '17px !important',
      },
    },
  }),
);

export const PromoGourmetTwo: React.FC<{ plan: Plan }> = ({ plan }) => {
  const classes = useStyles();
  return (
    <Box marginTop={1}>
      <Box
        component={'div'}
        sx={{
          backgroundColor:
            plan?.promo_subsidiary?.[0]?.identity_color ?? 'black',
        }}
        className={classes.chipOnTop}
      >
        <Typography
          fontFamily={'mr-eaves-xl-modern'}
          className={classes.chipOnTopText}
          sx={{ color: plan?.promo_subsidiary?.[0]?.text_color ?? 'white' }}
        >
          <strong style={{ fontWeight: 800 }}>Experiencia del mes</strong> |{' '}
          <span style={{ fontWeight: 400 }}>
            {plan?.promo_subsidiary?.[0]?.header_subsidiary}
          </span>
        </Typography>
      </Box>
      <Box
        paddingY={'5px'}
        position={'relative'}
        component={'div'}
        style={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTopRightRadius: '10px',
        }}
        border={'2px solid ' + plan?.promo_subsidiary?.[0]?.identity_color}
      >
        <Box paddingX={'10px'}>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            fontSize={{ xs: '15px', sm: '18px' }}
            fontWeight={700}
            textTransform={'uppercase'}
          >
            {plan?.promo_subsidiary?.[0]?.title}
          </Typography>
          <Typography
            className={classes.secondText}
            fontFamily={'mr-eaves-xl-modern'}
          >
            {plan?.promo_subsidiary?.[0]?.description_first_line}
          </Typography>
          <Typography
            className={classes.secondText}
            fontFamily={'mr-eaves-xl-modern'}
          >
            {plan?.promo_subsidiary?.[0]?.description_second_line}
          </Typography>
        </Box>
        <Box position={'absolute'} bottom={-1} right={0}>
          <Box width={'98px'} height={'79px'} position={'relative'}>
            <img
              src={plan?.promo_subsidiary?.[0]?.image ?? ''}
              alt="invitación"
              width={'100%'}
              height={'100%'}
              style={{ position: 'absolute', objectFit: 'contain' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
