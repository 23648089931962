import { ReactNode } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Box } from '@mui/material';

type LayoutProps = {
  children: ReactNode;
};
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Box position={'fixed'} zIndex={99999} top={0} left={0} width={'100%'}>
        <Header />
      </Box>
      <Box paddingTop={'85px'} minHeight={'calc(100vh - 85px)'}>
        {children}
      </Box>
      <Footer />
    </>
  );
};
