import { Box, Grid, Theme, Typography } from '@mui/material';
import { Certification } from '../remote-education-types';
import { createStyles, makeStyles } from '@mui/styles';
import { SectionEntityDetails } from './SectionEntityDetails';
import { SectionExtraBenefits } from './SectionExtraBenefits';
import { USPeso } from '../../plan/components/PlanNewCard';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      color: '#5A6EEC',
      fontSize: '50px !important',
      lineHeight: '52px !important',
      fontWeight: '800 !important',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px !important',
        lineHeight: '30px !important',
      },
    },
    description: {
      fontSize: '22px !important',
      lineHeight: '24px !important',
      paddingTop: '30px !important',
      '& p': {
        paddingBottom: '15px !important',
        margin: '0px 0px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '20px !important',
        paddingTop: '20px !important',
      },
    },
    twoTitle: {
      color: '#3E3431',
      fontSize: '28px !important',
      lineHeight: '28px !important',
      fontWeight: '800 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px !important',
        lineHeight: '24px !important',
      },
    },
    generalObjective: {
      color: '#3E3431',
      fontSize: '22px !important',
      lineHeight: '24px !important',
      paddingTop: '15px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '20px !important',
      },
    },
    regularPriceButton: {
      fontSize: '18px !important',
      lineHeight: '18px',
      borderColor: '#3E3431',
      color: '#3E3431',
    },
    regularPrice: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      color: '#3E3431',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px !important',
        lineHeight: '20px !important',
      },
    },
    specialOfferButton: {
      fontSize: '18px !important',
      lineHeight: '120%',
      background: 'linear-gradient(90deg, #FF787C 0%, #FF3C41 100%)',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    specialOfferDiscount: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      background: 'linear-gradient(90deg, #FF787C 0%, #FF3C41 100%)',
      WebkitBackgroundClip: 'text', // Para navegadores basados en WebKit (Chrome, Safari)
      backgroundClip: 'text', // Estándar moderno
      color: 'transparent', // Hace que el texto sea transparente para mostrar el fondo
      display: 'inline-block',
      [theme.breakpoints.down('md')]: {
        fontSize: '17px !important',
        lineHeight: '17px !important',
      },
    },
    specialOfferPriceDiscount: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      background: 'linear-gradient(90deg, #FF787C 0%, #FF3C41 100%)',
      WebkitBackgroundClip: 'text', // Para navegadores basados en WebKit (Chrome, Safari)
      backgroundClip: 'text', // Estándar moderno
      color: 'transparent', // Hace que el texto sea transparente para mostrar el fondo
      display: 'inline-block',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px !important',
        lineHeight: '20px !important',
      },
    },
    specialOfferRedirect: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      color: '#3E3431 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px !important',
        lineHeight: '14px !important',
      },
    },
  }),
);

export const SectionInfoSingle: React.FC<{
  data: Certification;
  discount?: number;
}> = ({ data, discount = 0.5 }) => {
  console.log({ data });
  const classes = useStyles();
  return (
    <Box
      paddingTop={{ xs: '30px', md: '49px' }}
      paddingX={{ xs: 2, lg: 0 }}
      display={'flex'}
      justifyContent={'center'}
    >
      <Grid
        container
        maxWidth={{ xs: '100%', sm: '595px', lg: '1040px' }}
        spacing={{ lg: 2 }}
      >
        <Grid item width={{ xs: '100%', lg: '595px' }}>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.title}
          >
            {data.title}
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.description}
          >
            {data.details.descriptionLong}
          </Typography>
          <SectionEntityDetails data={data.details} />
          <SectionExtraBenefits data={data.details.extraBenefits} />
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.twoTitle}
          >
            Objetivo general
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.generalObjective}
          >
            {data.details.generalObjective}
          </Typography>
          <Box
            position={'relative'}
            padding={{ xs: '20px 0px !important', md: '20px 0px !important' }}
            height={{ xs: '200px', md: '270px' }}
          >
            <Box
              component={'img'}
              alt="logo diplomado"
              src={data.details.courseLogoSrc}
              height={{ xs: '200px', md: '270px' }}
              position={'absolute'}
              style={{ objectFit: 'contain' }}
              width={'100%'}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg>
          <Box top={-10} position={'sticky'}>
            <Box
              paddingTop={{ xs: '15px', lg: '124px' }}
              paddingBottom={'30px'}
            >
              <Box paddingBottom={'15px'} borderRadius={'10px'}>
                <Box display={'flex'} paddingBottom={'10px'}>
                  <Box border={'1px solid black'} borderRadius={'5px'}>
                    <Typography
                      className={classes.regularPriceButton}
                      fontFamily={'mr-eaves-xl-modern'}
                      padding={{
                        xs: '2px 8px !important',
                        md: '5px 8px !important',
                      }}
                      paddingTop={{ md: '7px !important' }}
                    >
                      Precio regular
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  fontFamily={'mr-eaves-xl-modern'}
                  className={classes.regularPrice}
                  fontWeight={800}
                >
                  {USPeso.format(data.price)} MXN
                </Typography>
                {discount != 1 ? (
                  <Box paddingTop={{ xs: '15px', md: '15px' }}>
                    <Box
                      padding={{ xs: '19px 17px !immportant' }}
                      sx={{
                        background:
                          'linear-gradient(white, white) padding-box, linear-gradient(180deg, #FF3C41 0%, #FF787C 100%) border-box',
                        border: '2px solid transparent',
                        borderRadius: '10px', // Opcional: si quieres esquinas redondeadas
                        padding: {
                          xs: '19px 17px 19px 17px !important',
                          lg: '19px 17px 19px 17px !important',
                        },
                        margin: { xs: '0px 0px', lg: '0px 0px' },
                      }}
                    >
                      <Box
                        display={'flex'}
                        paddingBottom={'10px'}
                        alignItems={'center'}
                      >
                        <Box borderRadius={'5px'} overflow={'hidden'}>
                          <Typography
                            className={classes.specialOfferButton}
                            fontFamily={'mr-eaves-xl-modern'}
                            padding={'5px 8px !important'}
                            paddingTop={'7px'}
                            fontWeight={800}
                            color={'white'}
                          >
                            OFERTA ESPECIAL
                          </Typography>
                        </Box>
                        <Typography
                          paddingLeft={1}
                          className={classes.specialOfferDiscount}
                          fontFamily={'mr-eaves-xl-modern'}
                          paddingTop={'2px'}
                        >
                          {discount * 100}% de descuento
                        </Typography>
                      </Box>
                      <Typography
                        className={classes.specialOfferPriceDiscount}
                        fontWeight={800}
                        fontFamily={'mr-eaves-xl-modern'}
                      >
                        {USPeso.format(discount * data.price)} MXN
                      </Typography>
                      <Box>
                        <Typography
                          component={'a'}
                          href={'/planes'}
                          fontFamily={'mr-eaves-xl-modern'}
                          className={classes.specialOfferRedirect}
                        >
                          para suscriptores anuales de la Familia Animal
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
              <Box
                component={'div'}
                width={'100%'}
                borderRadius={'5px'}
                height={'58px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  textDecoration: 'none',
                  backgroundColor: 'black !important',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  color={'white'}
                  className={classes.buttomMoreInfo}
                  fontFamily={'mr-eaves-xl-modern'}
                  fontWeight={800}
                  paddingTop={'2px'}
                >
                  AGREGAR AL CARRITO
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
