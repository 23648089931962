import { Box, Collapse, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from 'react';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    buttonExpand: {
      width: '22px',
      height: '21px',
      transform: 'rotate(0deg)',
      transition: 'transform 0.2s ease-out',
      borderRadius: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        position: 'relative',
        cursor: 'pointer',
      },
    },
    buttonExpandOpen: {
      width: '22px',
      height: '21px',
      transform: 'rotate(-180deg)',
      transition: 'transform 0.2s ease-out',
      borderRadius: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
  }),
);
export const QuestionAnswer: React.FC<{ question: string; answer: string }> = ({
  question,
  answer,
}) => {
  const [more, setMore] = useState(false);

  const classes = useStyles();
  return (
    <Grid container paddingBottom={'20px'}>
      <Grid item xs>
        <Typography
          fontFamily={'mr-eaves-xl-modern'}
          fontSize={{ xs: '18px', md: '20px' }}
          lineHeight={{ xs: '20px', md: '22px' }}
          paddingRight={{ xs: '23px', lg: '28px' }}
          color={'white'}
        >
          {question}
        </Typography>
      </Grid>
      <Grid item maxWidth={{ xs: '15px', md: '20px' }}>
        <Box
          component={'div'}
          role="button"
          tabIndex={0}
          className={!more ? classes.buttonExpand : classes.buttonExpandOpen}
          onClick={() => setMore(!more)}
        >
          <img
            src={'/chevron-down.svg'}
            alt="Beneficios extra"
            width={'100%'}
            height={'100%'}
            style={{ position: 'absolute', objectFit: 'cover' }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={more}>
          <Typography
            paddingTop={'20px'}
            fontFamily={'mr-eaves-xl-modern'}
            fontSize={{ xs: '16px', md: '20px' }}
            lineHeight={{ xs: '20px', md: '24px' }}
            color={'#FFFFFFCC'}
          >
            {answer}
          </Typography>
        </Collapse>
      </Grid>
    </Grid>
  );
};
