import React, { useState } from 'react';
import { StudySectionEnum, SubjectDetails } from '../remote-education-types';
import { Box, Collapse, Grid, Typography } from '@mui/material';

export const StudySections: React.FC<{
  glossary: SubjectDetails[];
  type: StudySectionEnum;
}> = ({ glossary, type }) => {
  const [more, setMore] = useState(false);
  return (
    <Box
      display={'flex'}
      style={{
        backgroundColor: '#FFF8EF',
      }}
      justifyContent={'center'}
    >
      <Box
        paddingX={{ xs: 2, lg: 0 }}
        width={{ xs: '100%', lg: '1040px' }}
        paddingBottom={{ xs: '10px', lg: '45px' }}
      >
        <Grid container paddingTop={{ xs: '20px', lg: '40px' }}>
          <Grid item xs>
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              fontSize={{ xs: '30px', md: '50px' }}
              lineHeight={{ xs: '30px', md: '50px' }}
              paddingBottom={{ xs: '10px', lg: '30px' }}
              color={'#3E3431'}
              fontWeight={800}
            >
              Temario
            </Typography>
          </Grid>
          <Grid item maxWidth={{ xs: '20px', md: '40px' }}>
            <Box
              width={{ xs: '20px', md: '40px' }}
              height={{ xs: '20px', md: '40px' }}
              position={'relative'}
              component={'div'}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setMore(!more)}
            >
              <img
                src={!more ? '/more-icon.svg' : '/less-icon.svg'}
                alt={''}
                width={'100%'}
                height={'100%'}
                style={{ objectFit: 'contain', position: 'absolute' }}
              />
            </Box>
          </Grid>
        </Grid>
        <Collapse in={more}>
          {type === StudySectionEnum.withoutNumbers &&
            glossary.map((item, index) => (
              <Box paddingTop={{ xs: '10px', lg: 0 }} key={'glossary' + index}>
                <Typography
                  color={'#3E3431'}
                  fontFamily={'mr-eaves-xl-modern'}
                  fontSize={{ xs: '22px', md: '28px' }}
                  lineHeight={{ xs: '22px', md: '28px' }}
                  fontWeight={800}
                >
                  {item.title}
                </Typography>
                <ul
                  style={{
                    color: '#3E3431',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    margin: '0px 0px',
                  }}
                >
                  {item.sections.map((extra, indexInner) => (
                    <li key={'glossary section' + indexInner}>
                      <Typography
                        color={'#3E3431'}
                        paddingY={'2px'}
                        fontFamily={'mr-eaves-xl-modern'}
                        fontSize={{ xs: '16px', lg: '22px' }}
                        lineHeight={{ xs: '18px', lg: '24px' }}
                      >
                        {extra}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            ))}
          {type === StudySectionEnum.onlySubjects &&
            glossary.map((item, index) => (
              <Box paddingTop={{ xs: '10px', lg: 0 }} key={'glossary' + index}>
                <Typography
                  color={'#3E3431'}
                  fontFamily={'mr-eaves-xl-modern'}
                  fontSize={{ xs: '22px', md: '24px' }}
                  lineHeight={{ xs: '22px', md: '24px' }}
                >
                  {item.title}
                </Typography>
                <ul
                  style={{
                    color: '#3E3431',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    margin: '0px 0px',
                  }}
                >
                  {item.sections.map((extra, indexInner) => (
                    <li key={'glossary section' + indexInner}>
                      <Typography
                        color={'#3E3431'}
                        paddingY={'2px'}
                        fontFamily={'mr-eaves-xl-modern'}
                        fontSize={{ xs: '16px', lg: '22px' }}
                        lineHeight={{ xs: '18px', lg: '24px' }}
                      >
                        {extra}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            ))}
          {type === StudySectionEnum.withNumbers &&
            glossary.map((item, index) => (
              <Box paddingTop={{ xs: '10px', lg: 0 }} key={'glossary' + index}>
                <Typography
                  color={'#3E3431'}
                  fontFamily={'mr-eaves-xl-modern'}
                  fontSize={{ xs: '18px', md: '24px' }}
                  lineHeight={{ xs: '20px', md: '28px' }}
                  fontWeight={{ xs: 800 }}
                >
                  {item.title}
                </Typography>
                <Box
                  style={{
                    color: '#3E3431',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    margin: '0px 0px',
                  }}
                >
                  {item.sections.map((extra, indexInner) => (
                    <Box
                      key={'glossary section' + indexInner}
                      paddingBottom={'10px'}
                    >
                      <Typography
                        color={'#3E3431'}
                        paddingY={'2px'}
                        fontFamily={'mr-eaves-xl-modern'}
                        fontSize={{ xs: '18px', lg: '24px' }}
                        lineHeight={{ xs: '24px', lg: '30px' }}
                      >
                        {extra}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
        </Collapse>
      </Box>
    </Box>
  );
};
