import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { SocialBarNewPlans } from '../../../shared/components/layout/auth/SocialBarNewPlans';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: '46px 74px !important',
      paddingTop: '45px !important',
      background: '#000000',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '50px 10px !important',
      },
    },
    rootLogos: {
      padding: '16px 74px !important',
      paddingTop: '15px !important',
      background: '#F3F4F6',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '10px 10px !important',
      },
    },
    rootItems: {
      padding: '0 70px !important',
      [theme.breakpoints.down('lg')]: {
        padding: '0 0px !important',
      },
    },
    item: {
      fontSize: '16px !important',
      fontFamily: 'Inter',
      // fontWeight: 'bold !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
        paddingBottom: '10px',
      },
    },
    boxWithBorder: {
      color: '#FFFFFF',
      cursor: 'pointer',
      alignItems: 'center',
      display: 'flex',
      textDecoration: 'none',
      textAlign: 'center',
      borderRight: '1px solid #313131',
      [theme.breakpoints.down('xl')]: {
        borderRight: 'none',
        padding: '10px 0px 0px 0px',
      },
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
      },
    },
    boxWithoutBorder: {
      color: '#FFFFFF',
      cursor: 'pointer',
      textDecoration: 'none',
      alignItems: 'center',
      display: 'flex',
      textAlign: 'center',
      [theme.breakpoints.down('xl')]: {
        borderRight: 'none',
        padding: '10px 0px 0px 0px',
      },
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
      },
    },
    generalBox: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      background: '#000000',
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    flexCenterItemsMenu: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    socialBarClasses: {
      [theme.breakpoints.down('xl')]: {
        padding: '15px 0px 0px 0px !important',
      },
    },
  }),
);

const ItemsFooter = [
  {
    text: 'Aviso de privacidad',
    url: 'https://www.animalpolitico.com/aviso-privacidad',
  },
  {
    text: 'Términos y condiciones',
    url: 'https://animalpolitico.com/terminos-y-condiciones',
  },
  {
    text: 'Código de ética',
    url: 'https://www.animalpolitico.com/codigo-de-etica',
  },
  {
    text: 'Directorio General',
    url: 'https://www.animalpolitico.com/directorio',
  },
  {
    text: 'Preguntas frecuentes',
    url: 'https://www.animalpolitico.com/preguntas-frecuentes',
  },
  {
    text: '¿Quiénes somos?',
    url: 'https://www.animalpolitico.com/quienes-somos',
  },
  {
    text: 'Anúnciate con nosotros',
    url: 'https://www.animalpolitico.com/anunciate',
  },
];

export const PlansFooter: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container component={'div'}>
        <Grid item xs={12} className={classes.rootItems}>
          <Box display={'flex'} justifyContent={'center'}>
            <Box component={'a'} href="https://animalpolitico.com">
              <Box
                component={'img'}
                src="/logo-white.png"
                alt="logo"
                width={{ xs: 104, md: 150 }}
                height={{ xs: 36, md: 54 }}
                style={{ flexGrow: 1, objectFit: 'contain' }}
              />
            </Box>
          </Box>
          <Box paddingTop={'50px'} display={'flex'} justifyContent={'center'}>
            <SocialBarNewPlans />
          </Box>
        </Grid>
      </Grid>
      <Grid
        paddingTop={'50px'}
        container
        columnSpacing={{ xs: '21px', md: '50px' }}
      >
        {ItemsFooter.slice(0, 2).map((item, index) => (
          <Grid item xs={6} key={'foot-link' + index}>
            <Box
              component={'a'}
              sx={{ textDecoration: 'none !important' }}
              href={item.url}
              target="_blank"
            >
              <Typography
                textAlign={index === 0 ? 'end' : 'start'}
                color={'white'}
                fontSize={{ xs: '16px', md: '20px' }}
                fontFamily={'mr-eaves-xl-modern'}
              >
                {item.text}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
