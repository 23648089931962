import React, { useState } from 'react';
import { FormControl, Input, Theme } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    inputLabel: {
      marginBottom: '0px !important',
      fontFamily: 'mr-eaves-xl-modern',
      fontSize: '20px !important',
      lineHeight: '20px !important',
      [theme.breakpoints.down('md')]: {
        marginBottom: '0px !important',
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
  }),
);
const StyledLabel = styled('label')({
  marginBottom: 0,
});

const StyledInput = styled(Input)({
  border: '0.5px solid #909090',
  padding: '5px 10px',
  margin: '0!important',
  fontFamily: 'mr-eaves-xl-modern',
  borderRadius: 4,
  height: '36px !important',
});

const StyledIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
});

type InputDefaultProps = {
  label?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  value?: string | number | boolean;
  name?: string;
  type?: string;
  icon?: React.ReactElement;
  readOnly?: boolean;
  placeHolder?: string;
};

export const InputDefaultUserBilling: React.FC<InputDefaultProps> = ({
  readOnly = false,
  label,
  onChange,
  value,
  name,
  type,
  icon,
  placeHolder,
}) => {
  let defaultIcon = <></>;
  const classes = useStyles();
  const [passwordType, setPasswordType] = useState<undefined | string>(
    undefined,
  );

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  if (type === 'password') {
    defaultIcon = <StyledIcon onClick={handleVisibility} />;
  }

  return (
    <FormControl fullWidth>
      <StyledLabel className={classes.inputLabel} htmlFor="my-input">
        {label}
      </StyledLabel>
      <StyledInput
        disableUnderline
        id="my-input"
        placeholder={placeHolder ?? ''}
        onChange={onChange}
        type={passwordType || type}
        value={value}
        name={name}
        endAdornment={icon || defaultIcon}
        readOnly={readOnly}
      />
    </FormControl>
  );
};
