import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PostsPlansPage } from '../../../shared/constans';
import { SliderRedirects } from '../../../shared/components/slider/SliderRedirects';
import { NewsCard } from './NewsCard';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    subTitleOne: {
      fontSize: '30px !important',
      lineHeight: '35px !important',
      textAlign: 'center',
      color: '#000000',
      //margin: '0 auto !important',
      //marginTop: '10px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '20px !important',
      },
    },
    subTitleTwo: {
      fontSize: '40px !important',
      lineHeight: '40px !important',
      fontWeight: '700 !important',
      textAlign: 'center',
      //fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px !important',
        lineHeight: '24px !important',
        textTransform: 'uppercase',
        fontWeight: '800 !important',
        fontStyle: 'normal',
      },
    },
    textTwoMin: {
      fontSize: '60px !important',
      lineHeight: '60px !important',
      textAlign: 'center',
      fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
        textTransform: 'uppercase',
        fontWeight: 'bold !important',
        fontStyle: 'normal',
      },
    },
    textbeneffit: {
      fontSize: '20px !important',
      lineHeight: '24px !important',
      textAlign: 'center',
      fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '18px !important',
        fontStyle: 'normal',
      },
    },
    textStar: {
      fontSize: '14px !important',
      lineHeight: '14px !important',
      textAlign: 'center',
      fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
        lineHeight: '12px !important',
        fontStyle: 'normal',
      },
    },
  }),
);

const items = PostsPlansPage.map((item, index) => (
  <NewsCard key={'post' + index} item={item} />
));

export const NewsAttached: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      paddingY={{ xs: '10px', md: '33px' }}
      marginTop={{ xs: '0px', md: '50px' }}
    >
      <Typography
        marginTop={{ xs: 1, md: 2 }}
        className={classes.subTitleOne}
        fontFamily={'mr-eaves-xl-modern'}
      >
        Investigaciones periodísticas{' '}
        <Box component={'br'} sx={{ display: { xs: 'block', md: 'none' } }} />{' '}
        que fueron posibles
      </Typography>
      <Typography
        marginTop={{ xs: 1, md: '11px' }}
        className={classes.subTitleTwo}
        fontFamily={'mr-eaves-xl-modern'}
      >
        GRACIAS A TU APOYO
      </Typography>

      <Box
        marginTop={{ xs: '25px', sm: '60px' }}
        marginBottom={{ xs: '30px', sm: '40px' }}
      >
        <SliderRedirects items={items} infinite={true} controls={false} />
      </Box>
    </Box>
  );
};
