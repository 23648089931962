import { Box, Typography } from '@mui/material';
import { CertificationCard } from './CertificationCard';
import { cerificatesList } from '../remote-education-data';

export const Certifications: React.FC = () => {
  return (
    <Box
      paddingTop={'60px'}
      paddingBottom={'60px'}
      component={'div'}
      sx={{ backgroundColor: '#F1F1F1' }}
    >
      <Typography
        fontSize={{ xs: '28px', sm: '40px' }}
        lineHeight={{ xs: '32px', sm: '44px' }}
        fontFamily={'mr-eaves-xl-modern'}
        fontWeight={800}
        paddingBottom={2}
        textTransform={'uppercase'}
        textAlign={'center'}
      >
        Conoce nuestros Diplomados
      </Typography>
      <Box display={'flex'} justifyContent={'center'}>
        <Box width={{ xs: '100%', lg: '1040px' }} paddingX={2}>
          {cerificatesList.map((item, index) => (
            <CertificationCard
              key={'certification card ' + index}
              data={item}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
