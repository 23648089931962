import { Box, Grid, Typography } from '@mui/material';
import { BennefitElementMobile } from '../types/plan-types';
import { useState } from 'react';

export const PlanBennefitItemMobile: React.FC<{
  bennefit: BennefitElementMobile;
  withoutBorder?: boolean;
  paddingY?: number;
}> = ({ bennefit, withoutBorder = false, paddingY = 13 }) => {
  const [more, setMore] = useState(false);
  return (
    <Grid
      container
      paddingY={{ xs: '13px', md: paddingY + 'px' }}
      borderBottom={withoutBorder ? '' : '0.25px solid #90909090'}
    >
      <Grid item maxWidth={{ xs: '15px', md: '20px' }}>
        <Box
          width={{ xs: '15px', md: '20px' }}
          height={{ xs: '15px', md: '20px' }}
          position={'relative'}
        >
          <img
            src={bennefit.logo}
            alt={JSON.stringify(bennefit.text)}
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'contain', position: 'absolute' }}
          />
        </Box>
      </Grid>
      <Grid item xs>
        <Typography
          fontFamily={'mr-eaves-xl-modern'}
          fontSize={{ xs: '18px', md: '20px' }}
          lineHeight={{ xs: '20px', md: '22px' }}
          paddingLeft={'8px'}
          paddingRight={
            bennefit.extras.length ? '8px' : { xs: '23px', lg: '28px' }
          }
        >
          {bennefit.text}
        </Typography>
      </Grid>
      {bennefit.extras.length ? (
        <Grid item maxWidth={{ xs: '15px', md: '20px' }}>
          <Box
            width={{ xs: '15px', md: '20px' }}
            height={{ xs: '15px', md: '20px' }}
            position={'relative'}
            component={'div'}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setMore(!more)}
          >
            <img
              src={!more ? '/more-icon.svg' : '/less-icon.svg'}
              alt={JSON.stringify(bennefit.text)}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain', position: 'absolute' }}
            />
          </Box>
        </Grid>
      ) : null}
      {bennefit.extras.length ? (
        <Grid
          style={{
            maxHeight: !more ? 0 : bennefit.extras.length * 60 + 40,
            transition: 'max-height 0.2s ease-in',
            overflow: 'hidden',
          }}
          item
          xs={12}
        >
          <Box
            marginTop={'5px'}
            marginBottom={'5px'}
            marginX={'10px'}
            paddingRight={'10px'}
            borderRadius={'10px'}
            component={'div'}
            style={{ background: '#EFF1F7' }}
          >
            <ul
              style={{
                color: '#606060',
                paddingTop: '15px',
                paddingBottom: '15px',
                margin: '0px 0px',
              }}
            >
              {bennefit.extras.map((extra, index) => (
                <li key={'extra list' + index}>
                  <Typography
                    paddingY={'2px'}
                    fontFamily={'mr-eaves-xl-modern'}
                    fontSize={{ xs: '18px', lg: '20px' }}
                    lineHeight={{ xs: '20px', lg: '22px' }}
                    color={'#606060'}
                  >
                    {extra}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};
