import { Box, Typography } from '@mui/material';

export const NewsCard: React.FC<{
  item: { url: string; img: string; title: string };
}> = ({ item }) => {
  return (
    <Box paddingX={1} display={'flex'} justifyContent={'center'}>
      <Box>
        <Box display={'flex'} justifyContent={'center'}>
          <a href={item.url} target="_blank" rel="noreferrer noopener">
            <Box
              marginBottom={{ xs: 1, md: 2 }}
              component={'div'}
              overflow={'hidden'}
              borderRadius={{ xs: '10px', lg: '20px' }}
              position={'relative'}
              sx={{
                height: {
                  xs: '200px',
                  sm: '250px',
                  md: '275px',
                  lg: '275px',
                },
                width: {
                  xs: '200px',
                  md: 'auto',
                },
                maxWidth: {
                  xs: '200px',
                  md: '250px',
                  lg: '250px',
                },
                '&:hover': {
                  height: {
                    xs: '210px',
                    sm: '260px',
                    md: '285px',
                    lg: '285px',
                  },
                  maxWidth: {
                    xs: '210px',
                    md: '260px',
                    lg: '260px',
                  },
                  width: {
                    xs: '210px',
                    md: 'auto',
                  },
                },
                transition: 'height 0.2s ease-in, max-width 0.2s ease-in',
              }}
            >
              <img
                src={item.img}
                alt={item.title}
                width={'100%'}
                height={'100%'}
                style={{ objectFit: 'cover' }}
              />
            </Box>
          </a>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Box
            maxWidth={{
              xs: '200px',
              md: '250px',
              lg: '250px',
            }}
          >
            <a
              href={item.url}
              style={{ color: 'white', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Typography
                fontSize={{ xs: 12, md: 20 }}
                lineHeight={{ xs: '16px', md: '24px' }}
                fontWeight={400}
                color={'#000000'}
              >
                {item.title}
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
