import { useCallAction } from '@cobuildlab/react-simple-state';
import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import {
  checkoutPlanSubscription,
  updatePlanSubscription,
} from './stripe-actions';
import { useAuth } from '../auth/auth-hook';
import { getPostUrl } from '../auth/auth-utils';
import {
  AddUserBroadcastDataInput,
  PaymentMethod,
  Plan,
  StripeCoupon,
  StudentCredentialInput,
  Subscription,
  SubscriptionBillingInput,
  useAddUserPreferencesDataMutation,
  useCreateStudentCredentialMutation,
  useUpdateSubscriptionMutation,
} from '../../shared/types/generated';
import { openDialogAlert } from '../alert/alert-actions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientUpload } from '../../shared/apollo';
import { Interests } from '../payment/payment-types';
import { translateInterests } from '../payment/payment-utils';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
    button: {
      borderRadius: '5px!important',
      backgroundColor: '#FF3C41 !important',
      border: 'none!important',
      color: 'white!important',
      fontFamily: 'mr-eaves-xl-modern !important',
      fontWeight: '800 !important',
      fontSize: '20px !important',
      lineHeight: '20px !important',
      height: '55px !important',
      '&:disabled': {
        backgroundColor: '#FFCCCC!important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
        height: '45px !important',
      },
    },
  }),
);

interface StripeButtonProps {
  subscriptionBilling: SubscriptionBillingInput | null;
  studentCredential: StudentCredentialInput;
  coupon?: StripeCoupon | null;
  activeSubscription?: Subscription;
  plan?: Plan;
  interests?: Interests[];
  broadcastData?: AddUserBroadcastDataInput;
}

export const StripeButtonWithoutStore: React.FC<StripeButtonProps> = ({
  subscriptionBilling,
  studentCredential,
  coupon,
  activeSubscription,
  plan,
  interests,
  broadcastData,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { user } = useAuth();
  const [billingDisabled, setBillingDisabled] = useState(true);
  //const { plan } = useStore(changePlanStore);
  const navigate = useNavigate();

  const [updateUserPreferences] = useAddUserPreferencesDataMutation({
    onError: () => {
      openDialogAlert('Hubo un error al procesar las preferencias del usuario');
    },
  });

  const [updateUserSubscription] = useUpdateSubscriptionMutation({
    refetchQueries: ['CurrentUser'],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data?.updateSubscription) {
        setLoading(false);
        openDialogAlert('Suscripción actualizada', () =>
          navigate('/suscripciones'),
        );
      }
    },
    onError: (error) => {
      setLoading(false);
      console.error(error);
      openDialogAlert('Error al actualizar la suscripción.');
    },
  });

  const [callCheckoot] = useCallAction(checkoutPlanSubscription, {
    onError: (error) => {
      setLoading(false);
      console.error(error);
    },
  });

  const [callUpdate, loadingUpdate] = useCallAction(updatePlanSubscription, {
    onCompleted() {
      updateUserSubscription({
        variables: {
          data: {
            id: activeSubscription?.id,
            payment_method_type: PaymentMethod.StripeCard,
            plan_id: plan?.id,
            subscription_id: activeSubscription?.transaction_id as string,
            subscriptionBilling,
          },
        },
      });
    },
    onError: (error) => {
      setLoading(false);
      console.error(error);
      openDialogAlert(
        'Error al actualizar la suscripción. No se pudo realizar el pago, intente nuevamente',
      );
    },
  });

  const [callCreateStudentCredential] = useCreateStudentCredentialMutation({
    client: clientUpload,
    onCompleted: () => {
      callCheckoot({
        email: user?.email || '',
        priceId: plan?.stripe?.price_id || '',
        stateUrl: `post_url=${getPostUrl()}`,
        metadata: plan?.subscription_billing_is_required
          ? {
              subscriptionBilling: JSON.stringify(subscriptionBilling),
              post_url: getPostUrl(),
            }
          : undefined,
        coupon: coupon?.coupon ?? undefined,
      });
    },
    onError: (error) => {
      setLoading(false);
      console.error(error);
      openDialogAlert('Error al guardar la credencial estudiantil');
    },
  });

  const handleCheckout = (): void => {
    if (!plan?.stripe || !user) {
      return;
    }

    if (!interests?.length) {
      openDialogAlert('Seleccione al menos una pagina de interés');
      return;
    }

    const subscriptionBillingChecked =
      subscriptionBilling &&
      Object.keys(subscriptionBilling).find(
        (key) =>
          !subscriptionBilling[key as keyof SubscriptionBillingInput] &&
          key !== 'phone1' &&
          key !== 'address1' &&
          key !== 'internal_number' &&
          key !== 'country' &&
          key !== 'aditional_name',
      );

    if (
      plan?.subscription_billing_is_required &&
      subscriptionBillingChecked &&
      (!subscriptionBilling?.address ||
        !subscriptionBilling?.city ||
        !subscriptionBilling?.external_number ||
        !subscriptionBilling?.phone ||
        !subscriptionBilling?.postal_code ||
        !subscriptionBilling?.state)
    ) {
      openDialogAlert('Los datos de envio son obligatorios para el plan anual');
      return;
    }

    if (plan.subscription_student_credential_is_required) {
      const studentCredentialChecked: boolean =
        studentCredential?.file === null;

      if (studentCredentialChecked) {
        openDialogAlert(
          'Los datos de la credencial estudiantil son obligatorios',
        );
        return;
      }

      setLoading(true);

      callCreateStudentCredential({
        variables: {
          data: {
            file: studentCredential.file,
          },
        },
      });
      return;
    }

    if (activeSubscription) {
      callUpdate({
        subscriptionId: activeSubscription?.transaction_id as string,
        priceId: plan.stripe?.price_id || '',
      });
      return;
    }
    callCheckoot({
      email: user?.email,
      priceId: plan.stripe?.price_id || '',
      stateUrl: `post_url=${getPostUrl()}`,
      metadata: plan.subscription_billing_is_required
        ? {
            subscriptionBilling: JSON.stringify(subscriptionBilling),
            post_url: getPostUrl(),
          }
        : undefined,
      coupon: coupon?.coupon ?? undefined,
    });
  };
  useEffect(() => {
    const subscriptionBillingChecked =
      subscriptionBilling &&
      Object.keys(subscriptionBilling).find(
        (key) =>
          !subscriptionBilling[key as keyof SubscriptionBillingInput] &&
          key !== 'phone1' &&
          key !== 'address1' &&
          key !== 'internal_number' &&
          key !== 'country' &&
          key !== 'aditional_name',
      );
    //console.log('revision',!subscriptionBilling?.postal_code, subscriptionBilling?.postal_code);
    if (
      plan?.subscription_billing_is_required &&
      subscriptionBillingChecked &&
      (!subscriptionBilling?.address ||
        !subscriptionBilling?.city ||
        !subscriptionBilling?.external_number ||
        !subscriptionBilling?.phone ||
        !subscriptionBilling?.postal_code ||
        !subscriptionBilling?.state)
    ) {
      setBillingDisabled(true);
      return;
    }
    if (!interests?.length) {
      setBillingDisabled(true);
      return;
    }

    if (broadcastData?.must_send) {
      if (!broadcastData?.phone) {
        setBillingDisabled(true);
        return;
      }
    }

    if (plan?.subscription_student_credential_is_required) {
      const studentCredentialChecked: boolean =
        studentCredential?.file === null;

      if (studentCredentialChecked) {
        setBillingDisabled(true);
        return;
      }
    }
    setBillingDisabled(false);
  }, [
    subscriptionBilling,
    subscriptionBilling?.postal_code,
    studentCredential,
    plan,
    user,
    interests?.length,
    broadcastData?.must_send,
    broadcastData?.phone,
  ]);

  if (!plan?.stripe) {
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Stripe no esta configurado para este plan, contacte a su administrador
      </Typography>
    );
  }

  return (
    <ButtonDefault
      variant="contained"
      size="large"
      fullWidth
      disabled={billingDisabled}
      isLoading={loading || loadingUpdate}
      onClick={() => {
        if (
          plan?.subscription_student_credential_is_required &&
          billingDisabled
        ) {
          openDialogAlert(
            'Los datos de la credencial estudiantil son obligatorios',
          );
        }
        updateUserPreferences({
          variables: {
            data: {
              user_id: user?.id ?? 0,
              must_send: broadcastData?.must_send,
              phone: broadcastData?.phone,
              interests: translateInterests(interests ?? []),
            },
          },
          onCompleted: () => {
            handleCheckout();
          },
        });
      }}
      className={classes.button}
    >
      TARJETA DE DÉBITO/CRÉDITO
    </ButtonDefault>
  );
};
