import { Box, Divider, Grid, Theme, Typography } from '@mui/material';
import { Plan, PlanFrequency } from '../../../shared/types/generated';
import { createStyles, makeStyles } from '@mui/styles';
import { USPeso } from './PlanNewCard';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    planName: {
      fontSize: '40px !important',
      lineHeight: '40px !important',
      paddingBottom: '0px',
      [theme.breakpoints.down('md')]: {
        fontSize: '40px !important',
        lineHeight: '40px !important',
      },
    },
    planRealPrice: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      fontWeight: '700 !important',
      color: '#909090 !important',
      textDecoration: 'line-through',
      textDecorationThickness: '1px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
      },
    },
    planActualPrice: {
      fontSize: '40px !important',
      lineHeight: '40px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '40px !important',
        lineHeight: '40px !important',
      },
    },
    planActualPriceFequency: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      color: '#909090',
      fontWeight: '700 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
      },
    },
    planDiscount: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      color: '#FF3C41',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    firstPayment: {
      fontSize: '45px !important',
      lineHeight: '45px !important',
      fontWeight: '800 !important',
      color: '#ff3c41 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '40px !important',
        lineHeight: '40px !important',
      },
    },
    firstPaymentExtra: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px !important',
        lineHeight: '22px !important',
      },
    },
    planPriceAfter: {
      fontSize: '25px !important',
      lineHeight: '25px !important',
      fontWeight: '700 !important',
      paddingBottom: '5px',
      [theme.breakpoints.down('md')]: {
        fontSize: '21px !important',
        lineHeight: '18px !important',
      },
    },
  }),
);

export const PlanInfoTwoPromo: React.FC<{
  plan: Plan;
  discount: string;
  firstPayment: string;
}> = ({ plan, discount, firstPayment }) => {
  const classes = useStyles();
  return (
    <Grid container marginBottom={{ xs: 1, md: 2 }} display={'flex'}>
      <Grid item xs={8}>
        <Box>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planName}
          >
            {plan.title_front?.toLowerCase().includes('estándar')
              ? 'Mensual'
              : plan.title_front?.includes('profesional')
              ? 'Anual'
              : 'Estudiantil o docente'}
          </Typography>
          <Box display={'flex'}>
            <Box
              marginY={'10px'}
              paddingX={'10px'}
              paddingTop={'8px'}
              paddingBottom={'6px'}
              borderRadius={'5px'}
              border={'1px solid #ff3c41'}
            >
              <Typography
                fontFamily={'mr-eaves-xl-modern'}
                fontWeight={700}
                fontSize={18}
                color={'#ff3c41'}
                lineHeight={'16px'}
              >
                OFERTA ÚNICA
              </Typography>
            </Box>
          </Box>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.firstPayment}
          >
            ${firstPayment}
            <Typography
              component={'span'}
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.firstPaymentExtra}
            >
              / el primer mes
            </Typography>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} display={'flex'} justifyContent={'end'}>
        <Box height={{ xs: '100px' }} width={{ xs: '100px' }}>
          <img
            src={
              plan?.title?.toLowerCase().includes('estudiantil')
                ? '/student-plan-logo.png'
                : plan?.title?.toLowerCase().includes('estándar')
                ? '/standard-plan-logo.png'
                : '/pro-plan-logo.png'
            }
            alt="Logo de plan"
            width={'100%'}
            height={'100%'}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ margin: '5px 0px' }} />
      </Grid>
      <Grid item xs={12}>
        <Box marginRight={{ xs: 1, sm: 2 }}>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planPriceAfter}
          >
            Después
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planRealPrice}
          >
            {plan?.helper_text?.replace(' MXN', '')}
            {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planActualPrice}
            fontWeight={800}
          >
            {USPeso.format(Number(plan?.price))}
            <Typography
              component={'span'}
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.planActualPriceFequency}
            >
              {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
            </Typography>
          </Typography>
          <Typography
            component={'span'}
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planDiscount}
          >
            {discount}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
