import { Box, Theme, Typography } from '@mui/material';
import { PageAssociatedPlans } from '../../../shared/components/layout/pagesAssociatedPlans';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    buttomMoreInfo: {
      color: 'white',
      fontSize: '22px !important',
      lineHeight: '22px !important',
      textDecoration: 'none !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
  }),
);

export const SectionAssosiatedAndRedirects: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Box>
      <Box display={'flex'} justifyContent={'center'} mt={'30px'}>
        <Box width={{ xs: '350', md: '455px' }}>
          <PageAssociatedPlans />
        </Box>
      </Box>
      <Box paddingBottom={'20px'} display={'flex'} justifyContent={'center'}>
        <Box
          height={{ xs: '220px', md: '610px' }}
          width={'100%'}
          maxWidth={'1920px'}
          position={'relative'}
          component={'div'}
        >
          <Box
            component={'img'}
            width={'100%'}
            height={'100%'}
            display={{ xs: 'none', sm: 'block' }}
            src="/identidad.gif"
            style={{
              objectFit: 'contain',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <Box
            component={'img'}
            width={'100%'}
            height={'100%'}
            display={{ xs: 'block', sm: 'none' }}
            src="/identidad-mobile.gif"
            style={{
              objectFit: 'contain',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        </Box>
      </Box>
      <Box display={'flex'} paddingBottom={'36px'} justifyContent={'center'}>
        <Box
          component={'div'}
          width={{ xs: '328px', md: '508px' }}
          borderRadius={'5px'}
          height={{ xs: '48px', md: '58px' }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            textDecoration: 'none',
            backgroundColor: 'black !important',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/planes');
          }}
        >
          <Typography
            color={'white'}
            className={classes.buttomMoreInfo}
            fontFamily={'mr-eaves-xl-modern'}
            fontWeight={800}
            paddingTop={'2px'}
          >
            VER PLANES
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
