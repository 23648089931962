import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { SliderPlanBrands } from '../slider/SliderPlanBrands';

const IMAGES = [
  {
    href: 'https://animalpolitico.com',
    alt: 'logo',
    src: '/ap-logo-two.svg',
    sizes: {
      desktop: [66, 24],
      mobile: [48, 18],
    },
  },
  {
    href: 'https://animalpolitico.com/verificacion-de-hechos',
    alt: 'logo',
    src: '/sabueso-logo-two.svg',
    sizes: {
      desktop: [85, 10],
      mobile: [63, 8],
    },
  },
  {
    href: 'https://www.animalgourmet.com',
    alt: 'logo',
    src: '/animal-gourmet-logo-two.svg',
    sizes: {
      desktop: [48, 48],
      mobile: [33, 33],
    },
  },
  {
    href: 'https://animalpolitico.com/tendencias',
    alt: 'logo',
    src: '/animal-logo-two.svg',
    sizes: {
      desktop: [74, 36],
      mobile: [54, 27],
    },
  },
  {
    href: 'https://newsweekespanol.com',
    alt: 'logo',
    src: '/newsweeklogo-two.svg',
    sizes: {
      desktop: [81, 17],
      mobile: [60, 13],
    },
  },
];

export const PageAssociatedPlansEndless: React.FC = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const items = IMAGES.concat(IMAGES)
    .concat(IMAGES.slice(0, 2))
    .map((image) => (
      <a href={image.href} target="_blank">
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{ backgroundColor: '#eff1f7' }}
          justifyContent={'center'}
          height={{ xs: '50px', md: '80px' }}
          width={{ xs: '100%' }}
        >
          <img
            src={image.src}
            alt={image.alt}
            width={isDownMd ? image.sizes.mobile[0] : image.sizes.desktop[0]}
            height={isDownMd ? image.sizes.mobile[1] : image.sizes.desktop[1]}
            sizes={
              isDownMd
                ? image.sizes.mobile.join(':')
                : image.sizes.desktop.join(':')
            }
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </a>
    ));
  //<SliderPlanBrands items={items} infinite controls={false} />;
  /**<div id="infinite" className="highway-slider">
      <div className="container highway-barrier">
        <ul className="highway-lane">
          {items.map((item,index)=><li key={'car'+index} className="highway-car">{item}</li>)}
        </ul>
      </div>
    </div> */
  return <SliderPlanBrands items={items} infinite={true} controls={false} />;
};
