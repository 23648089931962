import { Certifications } from './components/Certifications';
import { FrecuentQuestions } from './components/FrecuentQuestions';
import { Layout } from './components/Layout';
import { SectionAllies } from './components/SectionAllies';
import { SectionAssosiatedAndRedirects } from './components/SectionAssosiatedAndRedirects';
import { SectionLogo } from './components/SectionLogo';

export const RemoteEducation: React.FC = () => {
  return (
    <>
      <Layout>
        <SectionLogo />
        <SectionAllies />
        <Certifications />
        <FrecuentQuestions />
        <SectionAssosiatedAndRedirects />
      </Layout>
    </>
  );
};
