import { Box } from '@mui/material';
import { Cart } from './Cart';
import { LogSubButton } from './LogSubButton';

export const Header: React.FC = () => {
  return (
    <>
      <Box
        height={'85px'}
        paddingX={{ xs: '10px', lg: '64px' }}
        position={'relative'}
        sx={{ backgroundColor: '#5A6EEC' }}
      >
        <Box
          width={'100%'}
          height={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box component={'a'} href="/educacion-remota">
              <Box
                component={'img'}
                src="/logo-educacion-distancia.png"
                alt="icono pajarito inteligente"
                height={'50px'}
                width={'46px'}
                paddingRight={{ xs: '15px', md: '40px' }}
              />
            </Box>
            <Box height={'30px'} borderLeft={'1px solid #FFFFFFAA'} />
            <Box component={'a'} href="/diplomados-a-distancia">
              <Box
                component={'img'}
                src="/logo-diplomados-distancia-azul.png"
                alt="icono pajarito inteligente"
                height={{ xs: '30px', md: '50px' }}
                width={{ xs: '117px', md: '195px' }}
                paddingLeft={{ xs: '15px', md: '40px' }}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Box>
              <LogSubButton />
            </Box>
            <Box paddingLeft={{ xs: '15px', md: '30px' }}>
              <Cart />
            </Box>
            <Box
              paddingLeft={{ xs: '15px', md: '30px' }}
              component={'img'}
              width={'34px'}
              height={'34px'}
              src="/hamburguer-white.svg"
              alt="Icono hamburguesa"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
