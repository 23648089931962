import React from 'react';
import {
  PaypalStatuses,
  Plan,
  usePlansQuery,
} from '../../shared/types/generated';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PaymentMethodLoader } from './components/PaymentMethodLoader';
import { PaymentMethodsNew } from './components/PaymentMehodsNew';
// import { getPostUrl, getTestMode } from '../auth/auth-utils';

export const PaymentMethodNew: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data, loading } = usePlansQuery({
    onCompleted(event) {
      console.log(event);
    },
    variables: {
      where: {
        status: PaypalStatuses.Active,
      },
    },
  });

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        {loading ? (
          <PaymentMethodLoader />
        ) : (
          <PaymentMethodsNew
            plan={
              data?.plans?.items?.filter(
                (plan) => plan?.slug === slug,
              )?.[0] as Plan
            }
          />
        )}
      </Grid>
    </Grid>
  );
};
