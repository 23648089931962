import { Box } from '@mui/material';

export const SectionLogo: React.FC = () => {
  const scrollToTop = (): void => {
    window.scrollTo({ top: 800, left: 0, behavior: 'smooth' });
  };
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box
        height={'500px'}
        width={'100%'}
        maxWidth={'1920px'}
        position={'relative'}
        component={'div'}
      >
        <Box
          component={'img'}
          width={'100%'}
          height={'100%'}
          display={{ xs: 'none', sm: 'block' }}
          src="/portada-educacion.jpg"
          style={{ objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
        />
        <Box
          component={'img'}
          width={'100%'}
          height={'100%'}
          display={{ xs: 'block', sm: 'none' }}
          src="/portada-educacion-mobile.jpg"
          style={{ objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
        />
        <Box
          position={'absolute'}
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          top={0}
          component={'div'}
          className="gradient-background"
        >
          <Box display={'flex'} alignItems={'end'}>
            <Box>
              <Box display={'flex'} justifyContent={'center'}>
                <Box
                  component={'img'}
                  src="/diplomados-oscuro.png"
                  alt="logo diplomado oscuro"
                  title="logo"
                  width={{ xs: '100%', sm: '420px' }}
                  height={'109px'}
                />
              </Box>
              <Box display={'flex'} justifyContent={'center'}>
                <Box
                  component={'img'}
                  src="/chevron-down.svg"
                  alt="logo diplomado oscuro"
                  width={'76px'}
                  height={'76px'}
                  onClick={() => scrollToTop()}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
