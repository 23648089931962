import { Box, Typography } from '@mui/material';
import { useAuth } from '../../auth/auth-hook';
import { Plan } from '../../../shared/types/generated';
import { getPostUrl } from '../../auth/auth-utils';

export const SubscribeButtonMobile: React.FC<{
  plan: Plan;
  backgroudColor: string;
}> = ({ plan, backgroudColor }) => {
  const { user } = useAuth();
  return (
    <Box
      component={'div'}
      role="button"
      tabIndex={0}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      style={{ background: backgroudColor, cursor: 'pointer' }}
      flex={1}
      borderRadius={'5px'}
      height={{ xs: '40px', md: '45px' }}
      onClick={() => {
        if (user)
          return (window.location.href = `/metodos-de-pago/${
            plan.slug
          }?post_url=${getPostUrl()}`);
        else {
          const redirectUrl = `${window.location.origin}/metodos-de-pago/${
            plan.slug
          }?post_url=${getPostUrl()}`;
          window.location.href = `${
            window.location.origin
          }/autenticacion?redirect_url=${encodeURIComponent(redirectUrl)}`;
        }
      }}
    >
      <Typography
        fontFamily={'mr-eaves-xl-modern'}
        fontSize={{ xs: '18px' }}
        lineHeight={{ xs: '22px' }}
        color={'#ffffff'}
        fontWeight={800}
        textAlign={'center'}
        textTransform={'uppercase'}
        paddingTop={'2px'}
      >
        Suscríbete
      </Typography>
    </Box>
  );
};
