import { Box, Typography } from '@mui/material';

export const LogSubButton: React.FC = () => {
  const url = window.location.href;
  return (
    <Box
      width={'213px'}
      height={'40px'}
      display={{ xs: 'none', sm: 'flex' }}
      justifyContent={'center'}
      alignItems={'center'}
      component={'div'}
      style={{ backgroundColor: 'black' }}
      borderRadius={'39px'}
    >
      <Box
        component={'a'}
        style={{ textDecoration: 'none' }}
        href={`https://suscripciones.animalpolitico.com/autenticacion?redirect_url=${url}`}
      >
        <Typography
          fontFamily={'mr-eaves-xl-modern'}
          fontSize="20px"
          lineHeight={'20px'}
          color={'white'}
        >
          Ingresar
        </Typography>
      </Box>
      <Typography
        fontFamily={'mr-eaves-xl-modern'}
        fontSize="20px"
        lineHeight={'20px'}
        color={'white'}
      >
        &nbsp;/&nbsp;
      </Typography>
      <Box
        component={'a'}
        style={{ textDecoration: 'none' }}
        href={'https://suscripciones.animalpolitico.com/planes'}
      >
        <Typography
          fontFamily={'mr-eaves-xl-modern'}
          fontSize="20px"
          lineHeight={'20px'}
          fontWeight={800}
          color={'white'}
        >
          Suscribirse
        </Typography>
      </Box>
    </Box>
  );
};
