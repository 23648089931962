import { Box, Grid } from '@mui/material';
import React from 'react';
import {
  PaypalStatuses,
  Plan,
  usePlansQuery,
} from '../../../shared/types/generated';
import { PlanProMobileTwo } from './PlanProMobileTwo';
import { PlanStudentMobile } from './PlanStudentMobile';
import { PlanStandardMobile } from './PlanStandardMobile';

export const PlansSection: React.FC = () => {
  const { data, loading } = usePlansQuery({
    variables: {
      where: {
        status: PaypalStatuses.Active,
      },
    },
    onError(error) {
      console.error(error);
    },
  });
  if (loading) {
    return null;
  }
  return (
    <Box
      paddingX={'10px'}
      marginBottom={1}
      display={'flex'}
      justifyContent={'center'}
      marginTop={{ xs: '20px', lg: '50px' }}
    >
      <Box maxWidth={{ xs: '420px', md: '1250px' }} width={'100%'}>
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 1, md: 2 }}>
            <PlanProMobileTwo
              plan={
                data?.plans?.items?.filter((item) =>
                  item?.title?.toLowerCase().includes('profesional'),
                )?.[0] as Plan
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 2, md: 1 }}>
            <PlanStandardMobile
              plan={
                data?.plans?.items?.filter((item) =>
                  item?.title?.toLowerCase().includes('estándar'),
                )?.[0] as Plan
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={3}>
            <PlanStudentMobile
              plan={
                data?.plans?.items?.filter((item) =>
                  item?.title?.toLowerCase().includes('estudiantil'),
                )?.[0] as Plan
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
