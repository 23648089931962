import { Plan } from '../../shared/types/generated';
import { BennefitsByPlan, BennefitsByPlanMobile } from './types/plan-types';

export const getSubscriptionPlans = (
  plans: Plan[] | undefined | null,
): {
  item1: Plan | undefined;
  item2: Plan | undefined;
  item3: Plan | undefined;
} => {
  if (plans) {
    const [item1, item2, item3] = plans;
    return {
      item1,
      item2,
      item3,
    };
  }

  return {
    item1: undefined,
    item2: undefined,
    item3: undefined,
  };
};

export const bennefitsData: BennefitsByPlan = {
  pro: [
    {
      logo: '/check-bennefit.svg',
      text: 'Animal Político libre de anuncios',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Acceso anticipado a reportajes',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Exclusivos Newsletters',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Talleres y eventos con nuestros periodistas',
    },
    {
      logo: '/star-bennefit.svg',
      text: 'Tote bag y libro firmado',
    },
  ],
  student: [
    {
      logo: '/check-bennefit.svg',
      text: 'Animal Político libre de anuncios',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Acceso anticipado a reportajes',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Exclusivos Newsletters',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Talleres y eventos con nuestros periodistas',
    },
  ],
  standard: [
    {
      logo: '/check-bennefit.svg',
      text: 'Animal Político libre de anuncios',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Acceso anticipado a reportajes',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Exclusivos Newsletters',
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Talleres y eventos con nuestros periodistas',
    },
  ],
};

export const bennefitsDataMobile: BennefitsByPlanMobile = {
  pro: [
    {
      logo: '/check-bennefit.svg',
      text: (
        <>
          <strong style={{ fontWeight: 800 }}>
            ¡Recibe un kit de bienvenida*
          </strong>{' '}
          en la puerta de tu casa! Incluye:
        </>
      ),
      extras: [
        'Una tote bag.',
        'Un libro autografiado.',
        'Una revista Newsweek en español.',
        'Un separador coleccionable.',
        'Una tarjeta de agradecimiento personalizada.',
      ],
    },
    {
      logo: '/check-bennefit.svg',
      text: <>Navega sin publicidad.</>,
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: <>Productos editoriales exclusivos</>,
      extras: [
        <>
          Newsletter <strong style={{ fontWeight: 800 }}>diario</strong> con el
          resumen de las noticias más importantes.
        </>,
        <>
          Newsletter de <strong style={{ fontWeight: 800 }}>El Plumaje</strong>{' '}
          exclusivo para suscriptores.
        </>,
        <>
          <strong style={{ fontWeight: 800 }}>Recetarios únicos</strong> de
          Animal Gourmet.
        </>,
        <>
          Acceso anticipado a los{' '}
          <strong style={{ fontWeight: 800 }}>reportajes especiales</strong> de
          Animal Político.
        </>,
      ],
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Comenta las noticias y recibe respuesta por parte del equipo.',
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Invitación a experiencias exclusivas de la Familia Animal',
      extras: [
        'Experiencias culinarias exclusivas de Animal Gourmet.',
        'Animal Mx te lleva a eventos culturales y premiers.',
        'Webinars con los periodistas más destacados de Animal Político.',
        'MasterClass, talleres presenciales y online con El Sabueso.',
      ],
    },
    {
      logo: '/check-bennefit.svg',
      text: (
        <>
          <strong style={{ fontWeight: 800 }}>Próximamente:</strong> Acceso
          exclusivo a la plataforma de{' '}
          <i>
            <strong style={{ fontWeight: 800 }}>Educación a distancia.</strong>
          </i>
        </>
      ),
      extras: [],
    },
  ],
  student: [
    {
      logo: '/check-bennefit.svg',
      text: <>Navega sin publicidad</>,
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: <>Productos editoriales exclusivos</>,
      extras: [
        <>
          Newsletter <strong style={{ fontWeight: 800 }}>diario</strong> con el
          resumen de las noticias más importantes.
        </>,
        <>
          Newsletter de <strong style={{ fontWeight: 800 }}>El Plumaje</strong>{' '}
          exclusivo para suscriptores.
        </>,
        <>
          <strong style={{ fontWeight: 800 }}>Recetarios únicos</strong> de
          Animal Gourmet.
        </>,
        <>
          Acceso anticipado a los{' '}
          <strong style={{ fontWeight: 800 }}>reportajes especiales</strong> de
          Animal Político.
        </>,
      ],
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Comenta las noticias y recibe respuesta por parte del equipo.',
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Webinars con los periodistas más destacados de Animal Político.',
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: 'MasterClasses, talleres presenciales y online con El Sabueso.',
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: (
        <>
          <strong style={{ fontWeight: 800 }}>Próximamente:</strong> Acceso
          exclusivo a la plataforma de{' '}
          <i>
            <strong style={{ fontWeight: 800 }}>Educación a distancia.</strong>
          </i>
        </>
      ),
      extras: [],
    },
  ],
  standard: [
    {
      logo: '/check-bennefit.svg',
      text: <>Navega sin publicidad.</>,
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: <>Productos editoriales exclusivos</>,
      extras: [
        <>
          Newsletter <strong style={{ fontWeight: 800 }}>diario</strong> con el
          resumen de las noticias más importantes.
        </>,
        <>
          Newsletter de <strong style={{ fontWeight: 800 }}>El Plumaje</strong>{' '}
          exclusivo para suscriptores.
        </>,
        <>
          <strong style={{ fontWeight: 800 }}>Recetarios únicos</strong> de
          Animal Gourmet.
        </>,
        <>
          Acceso anticipado a los{' '}
          <strong style={{ fontWeight: 800 }}>reportajes especiales</strong> de
          Animal Político.
        </>,
      ],
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Comenta las noticias y recibe respuesta por parte del equipo.',
      extras: [],
    },
    {
      logo: '/check-bennefit.svg',
      text: 'Invitación a experiencias exclusivas de la Familia Animal',
      extras: [
        'Experiencias culinarias exclusivas de Animal Gourmet.',
        'Animal Mx te lleva a eventos culturales y premiers.',
        'Webinars con los periodistas más destacados de Animal Político.',
        'MasterClass, talleres presenciales y online con El Sabueso.',
      ],
    },
    {
      logo: '/check-bennefit.svg',
      text: (
        <>
          <strong style={{ fontWeight: 800 }}>Próximamente:</strong> Acceso
          exclusivo a la plataforma de{' '}
          <i>
            <strong style={{ fontWeight: 800 }}>Educación a distancia.</strong>
          </i>
        </>
      ),
      extras: [],
    },
  ],
};

export const certificatesData = [
  {
    img: '',
    title: 'Diplomado 01',
    subtitle: 'Lorem ipsum odor amet mus eleifend em ipsum sitper',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Elementum mus eleifend integer libero nisl magna.',
  },
  {
    img: '',
    title: 'Diplomado 02',
    subtitle: 'Lorem ipsum odor amet mus eleifend em ipsum sitper',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Elementum mus eleifend integer libero nisl magna.',
  },
  {
    img: '',
    title: 'Diplomado 03',
    subtitle: 'Lorem ipsum odor amet mus eleifend em ipsum sitper',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Elementum mus eleifend integer libero nisl magna.',
  },
];
