import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    text: {
      color: 'white',
      fontSize: '22px !important',
      lineHeight: '24px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '18px !important',
      },
    },
  }),
);

export const SectionEntityDetailItem: React.FC<{
  logo: string;
  field: string;
  value: string;
}> = ({ logo, field, value }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems={'center'}
      paddingBottom={{ xs: '13px', lg: '15px' }}
    >
      <Grid item width={{ xs: '27px', lg: '46px' }}>
        <Box
          component={'img'}
          width={{ xs: '20px', lg: '25px' }}
          height={{ xs: '20px', lg: '25px' }}
          src={logo ?? '/'}
          style={{ objectFit: 'cover' }}
        />
      </Grid>
      <Grid item xs height={'100%'} display={'flex'} alignItems={'center'}>
        <Typography className={classes.text} fontFamily={'mr-eaves-xl-modern'}>
          <strong style={{ fontWeight: 800 }}>{field}</strong>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
