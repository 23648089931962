import { Box, Divider, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    firstTextExpandible: {
      fontSize: '24px !important',
      lineHeight: '27px !important',
      fontWeight: '800 !important',
      color: '#5A6AF9 !important',
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
        lineHeight: '20px !important',
      },
    },
    secondTextExpandible: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      color: '#5A6AF9 !important',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important',
        lineHeight: '20px !important',
      },
    },
  }),
);

export const CarrerSection: React.FC<{
  discount: string;
  withoutTopPadding?: boolean;
}> = ({ discount, withoutTopPadding = false }) => {
  const classes = useStyles();
  return (
    <Box
      component={'div'}
      paddingTop={withoutTopPadding ? '0px' : '20px'}
      paddingBottom={'20px'}
      style={{ background: 'white' }}
    >
      <Divider />
      <Box marginTop={2}>
        <Typography
          className={classes.firstTextExpandible}
          fontFamily={'mr-eaves-xl-modern'}
        >
          {discount}% de descuento
        </Typography>
        <Typography
          className={classes.secondTextExpandible}
          fontFamily={'mr-eaves-xl-modern'}
        >
          en diplomados de periodismo de la Universidad Carlos III de Madrid.
        </Typography>
      </Box>
      <Box
        display={'flex'}
        marginTop={1}
        marginBottom={1}
        justifyContent={'center'}
      >
        <Box
          component={'a'}
          href="/"
          target="_blank"
          borderRadius={'5px'}
          paddingY={'9px'}
          flex={1}
          style={{ border: '1px solid #5A6AF9', textDecoration: 'none' }}
        >
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            fontWeight={800}
            color={'#5A6AF9'}
            fontSize={{ xs: '18px', sm: '22px' }}
            lineHeight={{ xs: '18px', sm: '22px' }}
            paddingTop={'2px'}
            textAlign={'center'}
          >
            MÁS INFORMACIÓN
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
