import {
  Box,
  Checkbox,
  Collapse,
  Container,
  Grid,
  Radio,
  Theme,
  Typography,
} from '@mui/material';
import {
  InterestsEnum,
  Plan,
  StripeCoupon,
  StudentCredentialDataInput,
  StudentCredentialInput,
  Subscription,
  SubscriptionBillingInput,
  useValidateCouponLazyQuery,
} from '../../../shared/types/generated';
import { PaymentPlanInfo } from './PaymentPlanInfo';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/auth-hook';
import { createStyles, makeStyles } from '@mui/styles';
import { openDialogAlert } from '../../alert/alert-actions';
import { useEffect, useState } from 'react';
import { couponDiscountAmount } from '../../coupons/coupon-utils';
import { BILLING_INFO } from '../payment-constants';
import { InputDefaultCoupon } from '../../../shared/components/InputDefaultCoupon';
import { ShippingInfoNew } from './ShipingInfoNew';
import { Interests } from '../payment-types';
import { InputDefaultWpp } from '../../../shared/components/InputDefaultWpp';
import { StripeButtonWithoutStore } from '../../stripe/StripeWithoutStore';
import { PaypalWithoutStore } from '../../paypal/PaypalWithoutStore';
import { translateIncomeInterests } from '../payment-utils';
import { Helmet } from 'react-helmet';
import { StudentCredentialInfoTwo } from './StudentCredentialInfoTwo';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    notification: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '16px',
      border: '1px solid #999999',
      [theme.breakpoints.down('md')]: {
        padding: '16px',
      },
    },
    textRecipe: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    totalRecipe: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '21px !important',
        lineHeight: '21px !important',
      },
    },
    styledFormControlLabel: {
      height: ' 26px',
      fontFamily: 'mr-eaves-xl-modern !important',
      fontSize: '20px !important',
      lineHeight: '20px !important',
      [theme.breakpoints.down('md')]: {
        fontFamily: 'mr-eaves-xl-modern !important',
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    styleWhatChannel: {
      alignItems: 'start !important',
      fontFamily: 'mr-eaves-xl-modern !important',
      color: '#909090 !important',
      lineHeight: '19px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '15px !important',
        lineHeight: '17px !important',
      },
    },
  }),
);
export const PaymentMethodsNew: React.FC<{ plan: Plan }> = ({ plan }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const classes = useStyles();
  const [couponValue, setCouponValue] = useState('');
  const [interestsSelected, setInterestSelected] = useState<Interests[]>([]);
  const [subscriptionBilling, setSubscriptionBilling] =
    useState<SubscriptionBillingInput>(BILLING_INFO);
  const [studentCardFile, setStudentCardFile] = useState<File | null>(null);
  const [studentCredential, setStudentCredential] =
    useState<StudentCredentialInput>({ file: null });
  const [coupon, setCoupon] = useState<StripeCoupon | null>(null);
  const [openWhatsappInput, setOpenWhatsappInput] = useState(false);
  const [wppNumber, setWppNumber] = useState('');
  const subscriptionActive = user?.subscriptions?.find(
    (item) => item?.status === 'ACTIVE',
  );
  useEffect(() => {
    if (user) {
      if (user?.subscription_billing)
        setSubscriptionBilling(user.subscription_billing);
      if (user?.interests)
        setInterestSelected(
          translateIncomeInterests(
            user?.interests.map((int) => int?.interest as InterestsEnum),
          ),
        );
      if (user?.broadcast_user_data) {
        setWppNumber((user?.broadcast_user_data.phone ?? '') as string);
        setOpenWhatsappInput(
          (user?.broadcast_user_data?.must_send ?? false) as boolean,
        );
      }
    }
  }, [user]);

  const [callFetchCoupon, { loading: loadingCoupon }] =
    useValidateCouponLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const couponData = data.validateCoupon;
        if (!couponData?.plan || couponData?.plan.id !== plan?.id) {
          openDialogAlert('El cupón no es válido para este plan');
          return;
        }
        if (couponData.status === 'INACTIVE') {
          openDialogAlert('El cupón no es válido');
        }
        openDialogAlert('Cupón aplicado correctamente');
        setCoupon(couponData);
      },
      onError(error) {
        console.error('Error:', error.message);
        openDialogAlert('El cupón no es válido');
      },
    });

  const discount = couponDiscountAmount(
    coupon,
    parseInt(plan?.price as string),
  );

  if (!user?.email) {
    return (
      <Box className={classes.notification}>
        <Typography variant="subtitle1" fontWeight={700} mb={1}>
          Para poder disfrutar de nuestros servicios agrega tu correo
          electrónico
        </Typography>
        <Typography variant="subtitle2" color="primary" mb={1}>
          Actualmente facebook cambio sus políticas de privacidad y limita la
          información de tu cuenta.
        </Typography>
        <ButtonDefault onClick={() => navigate('/')}>
          IR AL PERFIL
        </ButtonDefault>
      </Box>
    );
  }
  //console.log(interestsSelected);
  //console.log({ subscriptionBilling },{couponValue});
  //console.log(wppNumber);
  const metaData = {
    title: '',
    description: '',
  };

  if (plan?.title?.toLowerCase()?.includes('profesional')) {
    metaData.title = 'Periodismo de Investigación Libre';
    metaData.description =
      'Disfruta de nuestro contenido por un año. Tu suscripción anual financia un periodismo independiente y de calidad, con beneficios especiales para ti.';
  } else if (plan?.title?.toLowerCase()?.includes('estudiantil')) {
    metaData.title = 'Periodismo de Investigación Libre';
    metaData.description =
      'Apoya el periodismo de calidad con tu suscripción. Si eres estudiante o docente, disfruta de descuentos especiales y acceso a contenido exclusivo para ti.';
  } else if (plan?.title?.toLowerCase()?.includes('estándar')) {
    metaData.title = 'Periodismo de Investigación';
    metaData.description =
      'Accede a un periodismo de investigación único mes a mes. Tu suscripción mensual apoya el trabajo independiente y te brinda beneficios increíbles.';
  }

  return (
    <Box component={'div'} sx={{ backgroundColor: 'white' }}>
      <Container sx={{ maxWidth: { md: 'sm', lg: 'lg' } }}>
        {metaData.title ? (
          <Helmet>
            <meta property="og:title" content={metaData.title} />
            <meta property="og:description" content={metaData.description} />

            <meta name="title" content={metaData.title} />
            <meta name="description" content={metaData.description} />
          </Helmet>
        ) : null}
        <Box paddingY={{ xs: '20px', lg: '30px' }}>
          <Grid container columnSpacing={2} rowSpacing={{ xs: 2, lg: 'auto' }}>
            <Grid item xs={12}>
              <Typography
                fontWeight={800}
                py={'10px'}
                fontSize={{ xs: '22px', md: '28px' }}
                lineHeight={{ xs: '22px', md: '28px' }}
                borderBottom={'1px solid #000000'}
                fontFamily={'mr-eaves-xl-modern'}
              >
                Información detallada
              </Typography>
              <Box padding={1}></Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <PaymentPlanInfo
                firstPayment={plan.slug === 'plan-estandar-50-off' ? 50 : 0}
                plan={plan}
                priceAfterDiscount={0}
              />
              {plan?.subscription_billing_is_required && (
                <Grid
                  paddingTop={'50px'}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography
                      pb={1}
                      fontFamily={'mr-eaves-xl-modern'}
                      fontSize={{ xs: '18px', md: '20px' }}
                      lineHeight={{ xs: '20px', md: '22px' }}
                      textAlign={'center'}
                    >
                      Ingresa tu información para el envío del{' '}
                      <Box
                        component={'br'}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                      />
                      <strong style={{ fontWeight: 800 }}>
                        kit de bienvenida
                      </strong>
                    </Typography>
                    <Typography
                      color={'#FF3C41'}
                      fontFamily={'mr-eaves-xl-modern'}
                      marginBottom={'10px'}
                      fontWeight={800}
                    >
                      {' '}
                      * Campos obligatorios{' '}
                    </Typography>
                    <ShippingInfoNew
                      subscriptionBilling={subscriptionBilling}
                      onChange={(name, value) => {
                        setSubscriptionBilling({
                          ...subscriptionBilling,
                          [name as keyof SubscriptionBillingInput]: value,
                        });
                      }}
                    />
                    <Typography
                      fontFamily={'mr-eaves-xl-modern'}
                      fontSize={{ xs: '15px', md: '16px' }}
                      lineHeight={{ xs: '18px', md: '16px' }}
                      marginTop={{ xs: '10px' }}
                      color={'#606060'}
                    >
                      Un{' '}
                      <Box
                        component={'strong'}
                        fontSize={{ xs: '15px', md: '16px' }}
                        lineHeight={{ xs: '18px', md: '16px' }}
                        style={{ fontWeight: 800 }}
                      >
                        asesor personal
                      </Box>{' '}
                      se comunicará contigo para la confirmación de los datos de
                      envío.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {plan?.subscription_student_credential_is_required && (
                <Box className={classes.box} mb={2}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        paddingTop={2}
                        fontFamily={'mr-eaves-xl-modern'}
                        pb={1}
                        fontWeight={700}
                        fontSize={'18px'}
                        textAlign="center"
                        paddingBottom={2}
                      >
                        Credenciales de estudiante
                      </Typography>
                      <StudentCredentialInfoTwo
                        studentCredentialCardFile={studentCardFile}
                        onDrop={(file) => setStudentCardFile(file)}
                        studentCredential={studentCredential}
                        onChange={(name, value) => {
                          setStudentCredential({
                            ...studentCredential,
                            [name as keyof StudentCredentialDataInput]: value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box paddingLeft={{ xs: '0px', lg: '50px' }}>
                <Box position={'relative'}>
                  <InputDefaultCoupon
                    label="¿Tienes un cupón?"
                    placeHolder="Ingresa el código"
                    value={couponValue}
                    readOnly={!!coupon}
                    onChange={(e) => setCouponValue(e.target.value)}
                  />
                  <Box
                    position={'absolute'}
                    right={30}
                    top={{ xs: 35, md: 43 }}
                  >
                    {!loadingCoupon ? (
                      <Box
                        component={'div'}
                        style={{
                          cursor: 'pointer',
                          color: '#606060',
                          fontWeight: 700,
                          fontFamily: 'mr-eaves-xl-modern',
                        }}
                        sx={{
                          fontSize: {
                            xs: '16px',
                            md: '20px',
                          },
                        }}
                        onClick={() =>
                          callFetchCoupon({
                            variables: {
                              couponCode: couponValue,
                              plan_id: plan?.id as number,
                            },
                          })
                        }
                      >
                        APLICAR
                      </Box>
                    ) : null}
                  </Box>
                </Box>
                <Box paddingTop={4}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography
                      fontWeight={350}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.textRecipe}
                    >
                      Subtotal
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.textRecipe}
                    >
                      $
                      {parseFloat(
                        plan.helper_text
                          ?.replace('$', '')
                          .replace(' MXN', '')
                          .replace(',', '') ?? '0',
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box paddingTop={2}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography
                      fontWeight={350}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.textRecipe}
                    >
                      Descuento
                    </Typography>
                    <Typography
                      color={'#FF3C41'}
                      fontWeight={400}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.textRecipe}
                    >
                      -${' '}
                      {(
                        parseFloat(
                          plan.helper_text
                            ?.replace('$', '')
                            .replace(' MXN', '')
                            .replace(',', '') ?? '0',
                        ) - (parseFloat(plan?.price ?? '') ?? 0)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box borderBottom={'1px solid #909090'} paddingY={2}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography
                      fontWeight={350}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.textRecipe}
                    >
                      Cupón
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.textRecipe}
                    >
                      - ${discount.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box paddingTop={2}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography
                      fontWeight={800}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.totalRecipe}
                    >
                      Total
                    </Typography>
                    <Typography
                      fontWeight={800}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.totalRecipe}
                    >
                      MXN $
                      {(parseFloat(plan?.price ?? '') - discount).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box paddingTop={4}>
                  <Typography
                    paddingBottom={'10px'}
                    fontWeight={700}
                    fontFamily={'mr-eaves-xl-modern'}
                    className={classes.textRecipe}
                  >
                    Te interesa el contenido de:
                  </Typography>
                  {Object.values(Interests).map((itemMap, index) => (
                    <Box
                      key={'radio' + index}
                      sx={{ cursor: 'pointer !important' }}
                      display={'flex'}
                      onClick={() => {
                        if (interestsSelected.includes(itemMap)) {
                          setInterestSelected(
                            interestsSelected.filter(
                              (item) => item !== itemMap,
                            ),
                          );
                        } else {
                          setInterestSelected([...interestsSelected, itemMap]);
                        }
                      }}
                    >
                      <Box display={'flex'} height={'38px'}>
                        <Radio
                          size="small"
                          style={{
                            padding: '9px 9px 9px 9px',
                            alignItems: 'start',
                          }}
                          checked={interestsSelected.includes(itemMap)}
                        />
                      </Box>
                      <Typography
                        className={classes.styledFormControlLabel}
                        paddingTop={{ xs: '12px', md: '9px' }}
                      >
                        {itemMap}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box paddingTop={'20px'}>
                  <Collapse in={openWhatsappInput}>
                    <Box paddingLeft={'9px'} paddingBottom={'20px'}>
                      <InputDefaultWpp
                        label="Compártenos tu número telefónico"
                        value={wppNumber}
                        type="tel"
                        onChange={(event) => setWppNumber(event.target.value)}
                      />
                    </Box>
                  </Collapse>
                  <Box
                    component={'div'}
                    sx={{ cursor: 'pointer !important' }}
                    onClick={() => setOpenWhatsappInput(!openWhatsappInput)}
                    display={'flex'}
                  >
                    <Box display={'flex'} height={'38px'}>
                      <Checkbox
                        size={'small'}
                        style={{ padding: '9px 9px 9px 9px' }}
                        checked={openWhatsappInput}
                      />
                    </Box>
                    <Typography
                      paddingTop={'11px'}
                      className={classes.styleWhatChannel}
                    >
                      Acepto unirme al{' '}
                      <strong style={{ fontWeight: 800 }}>
                        canal de difusión de WhatsApp
                      </strong>{' '}
                      para suscriptores con el fin de recibir anuncios de los
                      eventos exclusivos disponibles cada mes.
                    </Typography>
                  </Box>
                </Box>
                <Box paddingTop={'50px'}>
                  <Typography
                    paddingBottom={'20px'}
                    fontWeight={800}
                    fontFamily={'mr-eaves-xl-modern'}
                    textAlign={'center'}
                    className={classes.textRecipe}
                  >
                    ELIGE UN MÉTODO DE PAGO
                  </Typography>
                  <Box paddingBottom={'15px'}>
                    <StripeButtonWithoutStore
                      studentCredential={{
                        file: studentCardFile,
                      }}
                      broadcastData={{
                        user_id: user.id,
                        must_send: openWhatsappInput,
                        phone: wppNumber,
                      }}
                      plan={plan}
                      subscriptionBilling={subscriptionBilling}
                      coupon={coupon}
                      interests={interestsSelected}
                      activeSubscription={subscriptionActive as Subscription}
                    />
                  </Box>
                  <Box>
                    <PaypalWithoutStore
                      plan={plan}
                      subscriptionBilling={subscriptionBilling}
                      coupon={coupon}
                      activeSubscription={subscriptionActive as Subscription}
                      studentCredential={{
                        file: studentCardFile,
                      }}
                      broadcastData={{
                        user_id: user.id,
                        must_send: openWhatsappInput,
                        phone: wppNumber,
                      }}
                      interests={interestsSelected}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
