import { Box, Divider } from '@mui/material';

import { CertificationDetails } from '../remote-education-types';
import { SectionEntityDetailItem } from './SectionEntityDetailItem';
import { AlliesForSingle } from './AlliesForSingle';

export const SectionEntityDetails: React.FC<{ data: CertificationDetails }> = ({
  data,
}) => {
  return (
    <Box
      component={'div'}
      sx={{
        backgroundColor: '#5A6EEC',
        borderRadius: '5px',
        overflow: 'hidden',
        padding: { xs: '20px 15px !important', lg: '20px 30px !important' },
        marginTop: { xs: '20px', lg: '30px' },
      }}
    >
      <SectionEntityDetailItem
        logo="/icono_reloj.svg"
        field={'Duración: '}
        value={data.durationTime}
      />
      <SectionEntityDetailItem
        logo="/icono-red.svg"
        field={'Modalidad: '}
        value={data.mode}
      />
      <SectionEntityDetailItem
        logo="/icono-ponente.svg"
        field={'Imparte: '}
        value={data.entity}
      />
      <SectionEntityDetailItem
        logo="/icono-universidad.svg"
        field={'Universidad que acredita: '}
        value={data.creditsOf}
      />
      <Divider sx={{ borderColor: 'white' }} />
      <AlliesForSingle logos={data.logos} />
    </Box>
  );
};
