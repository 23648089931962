import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '30px !important',
      lineHeight: '35px !important',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
      },
    },
    subTitle: {
      fontSize: '20px !important',
      lineHeight: '25px !important',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    textDecide: {
      fontSize: '22px !important',
      lineHeight: '24px !important',
      fontWeight: '700 !important',
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
        fontWeight: '400 !important',
      },
    },
  }),
);
export const TipsSection: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      marginTop={{ xs: '30px', lg: '50px' }}
      component={'div'}
      py={{ xs: '40px', lg: '10px' }}
      style={{ backgroundColor: 'white' }}
    >
      <Box
        paddingX={'10px'}
        marginBottom={1}
        display={'flex'}
        justifyContent={'center'}
      >
        <Box maxWidth={'1250px'} width={'100%'}>
          <Grid container>
            <Grid
              item
              xs={12}
              lg={4}
              display={'flex'}
              justifyContent={{ xs: 'center', lg: 'start' }}
            >
              <Box
                position={'relative'}
                width={{ xs: 120, lg: 200 }}
                height={{ xs: 120, lg: 200 }}
                paddingY={{ xs: '0px', lg: '10px' }}
              >
                <img
                  src="aportacion.gif"
                  alt="aportacion voluntaria"
                  height={'100%'}
                  width={'100%'}
                  style={{ objectFit: 'contain' }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              display={'flex'}
              justifyContent={{ xs: 'center', lg: 'start' }}
              alignItems={'center'}
            >
              <Box
                maxWidth={{ xs: '256px', lg: '100%' }}
                paddingTop={{ xs: '20px', lg: '0px' }}
              >
                <Typography className={classes.title} fontFamily={'IvyJournal'}>
                  <strong style={{ fontWeight: 800 }}>Aportación</strong>{' '}
                  <i>económica</i>
                </Typography>
                <Typography
                  marginTop={{ xs: '10px', lg: '20px' }}
                  fontFamily={'mr-eaves-xl-modern'}
                  className={classes.subTitle}
                >
                  ¿Te interesa hacer una{' '}
                  <Box
                    component={'strong'}
                    sx={{ fontWeight: { xs: 800, lg: 400 } }}
                  >
                    aportación económica
                  </Box>{' '}
                  <Box component={'br'} display={{ xs: 'none', lg: 'block' }} />{' '}
                  voluntaria para apoyar nuestro trabajo?
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              display={'flex'}
              justifyContent={{ xs: 'center', lg: 'end' }}
              alignItems={'center'}
            >
              <Box paddingTop={{ xs: 2, lg: '0px' }}>
                <Typography
                  fontFamily={'mr-eaves-xl-modern'}
                  className={classes.textDecide}
                >
                  Tú decides la cantidad
                  <br />
                  que quieres donar.
                </Typography>
                <Box display={'flex'} marginTop={2} justifyContent={'center'}>
                  <Box
                    component={'a'}
                    href="/"
                    target="_blank"
                    borderRadius={'5px'}
                    paddingY={'9px'}
                    flex={1}
                    width={{ xs: 290, lg: 300 }}
                    style={{ textDecoration: 'none', backgroundColor: 'black' }}
                  >
                    <Typography
                      fontFamily={'mr-eaves-xl-modern'}
                      fontWeight={700}
                      color={'white'}
                      fontSize={{ xs: '18px', sm: '22px' }}
                      lineHeight={{ xs: '18px', sm: '22px' }}
                      textAlign={'center'}
                      paddingTop={'2px'}
                    >
                      ¡QUIERO APOYAR!
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
