import React, { useEffect, useState } from 'react';
import {
  PayPalButtons,
  PayPalScriptProvider,
  FUNDING,
} from '@paypal/react-paypal-js';
import { useAuth } from '../auth/auth-hook';
import { openDialogAlert } from '../alert/alert-actions';
import {
  AddUserBroadcastDataInput,
  Plan,
  StripeCoupon,
  StudentCredentialInput,
  Subscription,
  SubscriptionBillingInput,
  useAddUserPreferencesDataMutation,
  useCreatePaypalSubscriptionMutation,
  useCreateStudentCredentialMutation,
} from '../../shared/types/generated';
import { Typography } from '@mui/material';
import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import { getPostUrl } from '../auth/auth-utils';
import { APP_ENDPOINT, PAYPAL_CLIENT_ID } from '../../shared/constans';
import { clientUpload } from '../../shared/apollo';
import { Interests } from '../payment/payment-types';
import { translateInterests } from '../payment/payment-utils';

type PaypalProps = {
  subscriptionBilling: SubscriptionBillingInput | null;
  coupon?: StripeCoupon | null;
  activeSubscription?: Subscription;
  studentCredential: StudentCredentialInput;
  plan?: Plan;
  interests?: Interests[];
  broadcastData?: AddUserBroadcastDataInput;
};

const PaypalButton: React.FC<PaypalProps> = ({
  subscriptionBilling,
  coupon,
  activeSubscription,
  studentCredential,
  plan,
  interests,
  broadcastData,
}) => {
  const { user } = useAuth();
  const [billingDisabled, setBillingDisabled] = useState(true);

  const [callSubscriptionAction] = useCreatePaypalSubscriptionMutation({
    onError: (error) => {
      openDialogAlert(error.message);
    },
  });

  const [updateUserPreferences] = useAddUserPreferencesDataMutation({
    onCompleted: (data) => {
      console.log({ data });
    },
    onError: () => {
      openDialogAlert('Hubo un error al procesar las preferencias del usuario');
    },
  });

  const [callCreateStudentCredential] = useCreateStudentCredentialMutation({
    client: clientUpload,
    onCompleted: () => {
      // TODO: CAMBIAR URL DE VERIFICACIÓN
      updateUserPreferences({
        variables: {
          data: {
            user_id: user?.id ?? 0,
            must_send: broadcastData?.must_send,
            phone: broadcastData?.phone,
            interests: translateInterests(interests ?? []),
          },
        },
        onCompleted: () => {
          window.location.href =
            APP_ENDPOINT + '/agradecimiento?redirect_url=' + getPostUrl();
        },
      });
    },
    onError: (error) => {
      console.error(error);
      openDialogAlert('Error al guardar la credencial estudiantil');
    },
  });

  useEffect(() => {
    const subscriptionBillingChecked =
      subscriptionBilling &&
      Object.keys(subscriptionBilling).find(
        (key) =>
          !subscriptionBilling[key as keyof SubscriptionBillingInput] &&
          key !== 'phone1' &&
          key !== 'address1' &&
          key !== 'internal_number' &&
          key !== 'country' &&
          key !== 'aditional_name',
      );

    if (
      plan?.subscription_billing_is_required &&
      subscriptionBillingChecked &&
      (!subscriptionBilling?.address ||
        !subscriptionBilling?.city ||
        !subscriptionBilling?.external_number ||
        !subscriptionBilling?.phone ||
        !subscriptionBilling?.postal_code ||
        !subscriptionBilling?.state)
    ) {
      setBillingDisabled(true);
      return;
    }

    if (!interests?.length) {
      setBillingDisabled(true);
      return;
    }

    if (broadcastData?.must_send) {
      if (!broadcastData?.phone) {
        setBillingDisabled(true);
        return;
      }
    }

    if (plan?.subscription_student_credential_is_required) {
      const studentCredentialChecked: boolean =
        studentCredential?.file === null;

      if (studentCredentialChecked) {
        setBillingDisabled(true);
        return;
      }
    }
    setBillingDisabled(false);
  }, [
    subscriptionBilling,
    studentCredential,
    plan,
    user,
    interests?.length,
    broadcastData?.must_send,
    broadcastData?.phone,
  ]);

  const paypalButtonKey = `${plan?.paypal?.paypal_id}-${coupon?.paypal_coupon_plan}-${billingDisabled}`;

  const handleSubscription = async (data: OnApproveData): Promise<void> => {
    const { subscriptionID, orderID } = data;
    callSubscriptionAction({
      variables: {
        data: {
          planId: plan?.id,
          subscriptionId: subscriptionID,
          orderId: orderID,
          subscriptionUrl: getPostUrl(),
          billingInfo: subscriptionBilling,
          coupon: coupon?.coupon ?? undefined,
        },
      },
      onCompleted() {
        callCreateStudentCredential({
          variables: {
            data: {
              file: studentCredential.file,
            },
          },
        });
      },
    });
  };
  console.log(
    'paypal:',
    billingDisabled || !!activeSubscription,
    { billingDisabled },
    !!activeSubscription,
  );
  return plan && plan.paypal ? (
    <PayPalButtons
      key={paypalButtonKey}
      fundingSource={FUNDING.PAYPAL}
      disabled={billingDisabled || !!activeSubscription}
      onClick={() => {
        if (
          plan?.subscription_student_credential_is_required &&
          billingDisabled
        ) {
          openDialogAlert(
            'Los datos de la credencial estudiantil son obligatorios',
          );
        }
      }}
      createSubscription={async (data, actions) => {
        return actions.subscription
          .create({
            plan_id:
              coupon?.paypal_coupon_plan && coupon.paypal_coupon_plan !== ''
                ? coupon?.paypal_coupon_plan
                : (plan?.paypal?.paypal_id as string),
            subscriber: {
              email_address: user?.email,
            },
            custom_id: user?.email,
          })

          .then((orderId: string) => {
            return orderId;
          });
      }}
      style={{ label: 'subscribe' }}
      onApprove={handleSubscription}
      onError={(err) => {
        console.log({ err });
        return openDialogAlert(
          'Tenemos problemas para realizar la suscripción',
        );
      }}
    />
  ) : null;
};

export const PaypalWithoutStore: React.FC<PaypalProps> = ({
  subscriptionBilling,
  coupon,
  activeSubscription,
  studentCredential,
  plan,
  interests,
  broadcastData,
}) => {
  if (!plan?.paypal)
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Paypal no esta configurado para este plan, contacte a su administrador
      </Typography>
    );
  return (
    <PayPalScriptProvider
      options={{
        clientId: PAYPAL_CLIENT_ID,
        components: 'buttons',
        intent: 'subscription',
        vault: true,
      }}
    >
      <PaypalButton
        plan={plan}
        subscriptionBilling={subscriptionBilling}
        coupon={coupon}
        interests={interests}
        activeSubscription={activeSubscription}
        studentCredential={studentCredential}
        broadcastData={broadcastData}
      />
    </PayPalScriptProvider>
  );
};
