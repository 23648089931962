import React from 'react';
import { LogoData } from '../remote-education-types';
import { Box, Grid } from '@mui/material';

export const AlliesForSingle: React.FC<{ logos: LogoData[] }> = ({ logos }) => {
  return (
    <Grid container paddingTop={'15px'} spacing={2} justifyContent={'center'}>
      {logos.map((image, index) => (
        <Grid
          item
          xs={6}
          lg={4}
          key={'image allie single' + index}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            component={'img'}
            src={image.src}
            alt={image.alt}
            width={{ xs: image.sizes.mobile[0], md: image.sizes.desktop[0] }}
            height={{ xs: image.sizes.mobile[1], md: image.sizes.desktop[1] }}
            //sizes={{xs:image.sizes.mobile.join(':'), md:image.sizes.desktop.join(':')}}
            style={{ objectFit: 'contain' }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
