import { Grid } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { SubscriptionBillingInput } from '../../../shared/types/generated';
import { InputDefaultUserBilling } from '../../../shared/components/InputDefaultUserBilling';

type ShippingInfoProps = {
  subscriptionBilling: SubscriptionBillingInput;
  onChange: (name: string, value: string) => void;
};
export const ShippingInfoNew: React.FC<ShippingInfoProps> = ({
  subscriptionBilling,
  onChange,
}) => {
  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    onChange(
      event.target.name as keyof SubscriptionBillingInput,
      event.target.value as string,
    );
  };

  return (
    <Grid container spacing={'10px'}>
      <Grid item xs={12}>
        <InputDefaultUserBilling
          label="Nombre de la calle*"
          name="address"
          value={subscriptionBilling.address as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputDefaultUserBilling
          label="Número exterior*"
          name="external_number"
          value={subscriptionBilling.external_number as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputDefaultUserBilling
          label="Número interior"
          name="internal_number"
          value={subscriptionBilling.internal_number as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputDefaultUserBilling
          label="Colonia"
          name="address1"
          value={subscriptionBilling.address1 as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputDefaultUserBilling
          label="Ciudad*"
          name="city"
          value={subscriptionBilling.city as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputDefaultUserBilling
          label="Estado*"
          name="state"
          value={subscriptionBilling.state as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputDefaultUserBilling
          label="País"
          name="country"
          value={subscriptionBilling.country as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={6}>
        <InputDefaultUserBilling
          label="Código postal*"
          name="postal_code"
          value={subscriptionBilling.postal_code as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputDefaultUserBilling
          label="Número telefónico de contacto*"
          name="phone"
          value={subscriptionBilling.phone as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputDefaultUserBilling
          label="Número telefónico de contacto adicional (opcional)"
          name="phone1"
          value={subscriptionBilling.phone1 as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputDefaultUserBilling
          label="Nombre de la persona que recibe (opcional)"
          name="aditional_name"
          value={subscriptionBilling.aditional_name as string}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
    </Grid>
  );
};
