import { StudentCredentialDataInput } from '../../shared/types/generated';

export const BILLING_INFO = {
  address: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal_code: '',
  phone: '',
  phone1: '',
  external_number: '',
  internal_number: '',
  aditional_name: '',
};

export const STUDENT_CREDENTIAL_INFO: StudentCredentialDataInput = {
  school: '',
  school_contact_number: '',
};
