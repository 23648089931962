import { Box, Grid, Theme, Typography } from '@mui/material';
import { Certification } from '../remote-education-types';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
      },
    },
    subTitle: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      display: '-webkit-box',
      '-webkit-line-clamp': '3' /* Number of lines to show */,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    buttomMoreInfo: {
      color: '#5A6EEC',
      fontSize: '22px !important',
      lineHeight: '22px !important',
      textDecoration: 'none !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    regularPriceButton: {
      fontSize: '18px !important',
      lineHeight: '18px',
    },
    regularPrice: {
      fontSize: '24px !important',
      lineHeight: '28px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px !important',
        lineHeight: '20px !important',
      },
    },
    specialOfferButton: {
      fontSize: '22px !important',
      lineHeight: '22px',
      background: 'linear-gradient(90deg, #FF787C 0%, #FF3C41 100%)',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    specialOfferDiscount: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      background: 'linear-gradient(90deg, #FF787C 0%, #FF3C41 100%)',
      WebkitBackgroundClip: 'text', // Para navegadores basados en WebKit (Chrome, Safari)
      backgroundClip: 'text', // Estándar moderno
      color: 'transparent', // Hace que el texto sea transparente para mostrar el fondo
      display: 'inline-block',
      [theme.breakpoints.down('md')]: {
        fontSize: '17px !important',
        lineHeight: '17px !important',
      },
    },
    specialOfferPriceDiscount: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      background: 'linear-gradient(90deg, #FF787C 0%, #FF3C41 100%)',
      WebkitBackgroundClip: 'text', // Para navegadores basados en WebKit (Chrome, Safari)
      backgroundClip: 'text', // Estándar moderno
      color: 'transparent', // Hace que el texto sea transparente para mostrar el fondo
      display: 'inline-block',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px !important',
        lineHeight: '20px !important',
      },
    },
    specialOfferRedirect: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      color: 'black !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px !important',
        lineHeight: '14px !important',
      },
    },
  }),
);

export const CertificationCardAlt: React.FC<{
  data: Certification;
  discount?: number;
}> = ({ data, discount = 0.5 }) => {
  const classes = useStyles();
  return (
    <Box
      marginBottom={'16px'}
      borderRadius={'10px'}
      style={{
        boxShadow: '0px 4px 5px 0px #89898940',
        backgroundColor: 'white',
      }}
      overflow={'hidden'}
    >
      <Grid container justifyContent={'stretch'}>
        <Grid item xs={12} height={{ xs: '174px' }} position={'relative'}>
          <Box
            component={'img'}
            width={'100%'}
            height={'100%'}
            src={data?.imageMobile ?? '/'}
            style={{ objectFit: 'cover', position: 'absolute' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            paddingY={{ xs: '18px', lg: '20px' }}
            paddingX={{ xs: '13px', lg: '27px' }}
          >
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.title}
              fontWeight={800}
              marginBottom={'15px'}
            >
              {data.title}
            </Typography>
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.subTitle}
              marginBottom={'15px'}
            >
              {data.description}
            </Typography>
            <Box marginBottom={'15px'}>
              {discount != 1 ? (
                <Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box borderRadius={'5px'} overflow={'hidden'}>
                      <Typography
                        className={classes.specialOfferButton}
                        fontFamily={'mr-eaves-xl-modern'}
                        padding={'5px 8px !important'}
                        paddingTop={'7px'}
                        fontWeight={800}
                        color={'white'}
                      >
                        OFERTA ESPECIAL
                      </Typography>
                    </Box>
                    <Typography
                      paddingLeft={1}
                      className={classes.specialOfferDiscount}
                      fontFamily={'mr-eaves-xl-modern'}
                      paddingTop={'2px'}
                    >
                      {discount * 100}% de descuento
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      component={'a'}
                      href={'/planes'}
                      fontFamily={'mr-eaves-xl-modern'}
                      className={classes.specialOfferRedirect}
                    >
                      para suscriptores anuales de la Familia Animal
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Box
              marginBottom={'15px'}
              component={'a'}
              href={`/diplomados-a-distancia/${data.slug}`}
              width={'100%'}
              borderRadius={'5px'}
              border={'1px solid #5A6EEC'}
              height={{ xs: '48px', lg: '58px' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                color={'#5A6EEC'}
                className={classes.buttomMoreInfo}
                fontFamily={'mr-eaves-xl-modern'}
                fontWeight={800}
                paddingTop={'2px'}
              >
                Ver programa completo
              </Typography>
            </Box>
            <Box
              component={'div'}
              width={'100%'}
              borderRadius={'5px'}
              height={{ xs: '50px', lg: '60px' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                textDecoration: 'none',
                backgroundColor: 'black !important',
                cursor: 'pointer',
              }}
            >
              <Typography
                color={'white'}
                className={classes.buttomMoreInfo}
                fontFamily={'mr-eaves-xl-modern'}
                fontWeight={800}
                paddingTop={'2px'}
              >
                AGREGAR AL CARRITO
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
