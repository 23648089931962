import { Box, Button, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/auth-hook';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    logoClasses: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'start !important',
      },
    },
    button: {
      color: 'white !important',
      height: '38px !important',
      border: '1px solid white !important',
      width: '140px',
      backgroundColor: 'black !important',
      borderRadius: '50px !important',
      [theme.breakpoints.down('md')]: {
        width: '100px',
        fontSize: '14px !important',
        height: '24px !important',
        borderRadius: '12px !important',
      },
    },
    invited: {
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      width: '100%',
    },
  }),
);
export const PlansHeader: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuth();
  return (
    <Box
      style={{ background: 'black' }}
      height={'75px'}
      justifyContent={'end'}
      alignItems={'center'}
      display={'flex'}
      position={'relative'}
      paddingX={{ xs: '10px', md: '30px' }}
    >
      <Box className={classes.logoClasses}>
        <Box
          component={'a'}
          sx={{
            paddingLeft: { xs: '10px', md: '0px' },
            display: { xs: 'none', md: 'block' },
          }}
          href="https://animalpolitico.com"
        >
          <img
            src="/logo.svg"
            alt="logo"
            width={130}
            height={40}
            style={{ flexGrow: 1, objectFit: 'contain' }}
          />
        </Box>
        <Box
          component={'a'}
          sx={{ paddingLeft: '10px', display: { xs: 'block', md: 'none' } }}
          href="https://animalpolitico.com"
        >
          <img
            src="/logo-red-tiny.png"
            alt="logo"
            width={27}
            height={35}
            style={{ flexGrow: 1, objectFit: 'contain' }}
          />
        </Box>
      </Box>
      {!user && (
        <>
          <Button
            className={classes.button}
            variant="contained"
            color="inherit"
            disableElevation
            style={{
              textTransform: 'capitalize',
              margin: 'none !important',
              border: 'none !important',
            }}
            onClick={() => navigate('/autenticacion')}
          >
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              paddingTop={{ xs: '2px' }}
              fontSize={{ xs: '14px', md: '16px' }}
            >
              Ingresar
            </Typography>
          </Button>
        </>
      )}
    </Box>
  );
};
