import { Box, Grid, Hidden, Typography } from '@mui/material';

export const ContactUsMobile: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: 'white' }} paddingY={'20px'}>
      <Grid container columnSpacing={{ xs: '0px', md: '100px' }}>
        <Grid item xs={12} lg={6}>
          <Box
            component={'div'}
            display={'flex'}
            justifyContent={{ xs: 'center', lg: 'end' }}
          >
            <Box width={{ xs: '220px', md: '320px' }}>
              <Box display={'flex'} justifyContent={'center'}>
                <Box
                  height={{ xs: '100px', lg: '221px' }}
                  width={{ xs: '100px', lg: '221px' }}
                  marginRight={{ xs: 1, sm: 2 }}
                  position={'relative'}
                >
                  <img
                    src={'/comunicate.png'}
                    alt="Logo de plan"
                    width={'100%'}
                    height={'100%'}
                    style={{ position: 'absolute', objectFit: 'contain' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            component={'div'}
            display={'flex'}
            justifyContent={{ xs: 'center', lg: 'start' }}
          >
            <Box width={{ xs: '220px', md: '320px' }}>
              <Box marginBottom={'20px'}>
                <Typography
                  textAlign={'center'}
                  fontFamily={'mr-eaves-xl-modern'}
                  fontWeight={800}
                  fontSize={{ xs: '22px', md: '40px' }}
                  lineHeight={{ xs: '26px', md: '44px' }}
                >
                  Comunícate
                </Typography>
                <Typography
                  fontWeight={400}
                  textAlign={'center'}
                  fontFamily={'mr-eaves-xl-modern'}
                  fontSize={{ xs: '18px', lg: '22px' }}
                  lineHeight={{ xs: '18px', lg: '22px' }}
                >
                  CON UN ASESOR PERSONAL PARA
                </Typography>
              </Box>
              <Hidden mdUp={true} mdDown={false}>
                <Grid container marginBottom={'20px'} alignItems={'center'}>
                  <Grid item xs={6} borderRight={'1px solid black'}>
                    <Typography
                      textAlign={'center'}
                      fontFamily={'mr-eaves-xl-modern'}
                      lineHeight={'19px'}
                    >
                      Planes <br /> corporativos <br /> especiales
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      textAlign={'center'}
                      fontFamily={'mr-eaves-xl-modern'}
                      lineHeight={'16px'}
                    >
                      Planes <br /> universitarios
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp={false} mdDown={true}>
                <Box>
                  <ul>
                    <li>
                      <Typography
                        fontSize={'22px'}
                        lineHeight={'24px'}
                        fontWeight={400}
                        fontFamily={'mr-eaves-xl-modern'}
                      >
                        Planes corporativos especiales
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        fontSize={'22px'}
                        lineHeight={'24px'}
                        fontWeight={400}
                        fontFamily={'mr-eaves-xl-modern'}
                      >
                        Planes Universitarios
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Hidden>
              <Box display={'flex'} justifyContent={'center'}>
                <Box
                  component={'a'}
                  href="https://wa.me/5215610190580"
                  target="_blank"
                  borderRadius={'5px'}
                  paddingTop={'12px'}
                  paddingBottom={'10px'}
                  width={'100%'}
                  style={{
                    backgroundColor: 'black',
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    fontFamily={'mr-eaves-xl-modern'}
                    fontWeight={700}
                    color={'white'}
                    fontSize={{ xs: '16px', sm: '22px' }}
                    lineHeight={{ xs: '16px', sm: '22px' }}
                    textAlign={'center'}
                  >
                    ESCRÍBENOS
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
