import { Box, Divider, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    containerBox: {
      width: '440px',
      overflow: 'hidden',
      position: 'relative',
      padding: '20px 33px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
        padding: '20px 16px !important',
      },
    },
  }),
);

export const ExtraBennefitsMobile: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      marginBottom={'16px'}
      marginTop={'32px'}
      paddingX={2}
      display={'flex'}
      justifyContent={'center'}
    >
      <Box component={'div'} className={classes.containerBox}>
        <Typography
          marginBottom={'20px'}
          textAlign={'center'}
          fontSize={{ xs: '22px', lg: '40px' }}
          lineHeight={{ xs: '22px', lg: '40px' }}
          fontFamily={{ xs: 'mr-eaves-xl-modern' }}
        >
          BENEFICIOS{' '}
          <Typography
            fontSize={{ xs: '22px', lg: '40px' }}
            lineHeight={{ xs: '22px', lg: '40px' }}
            fontWeight={800}
            component={'span'}
            fontFamily={{ xs: 'mr-eaves-xl-modern' }}
          >
            ADICIONALES
          </Typography>
        </Typography>
        <Divider style={{ borderColor: '#909090 !important' }} />
        <Typography
          marginY={'20px'}
          textAlign={'center'}
          fontSize={{ xs: '18px', lg: '20px' }}
          lineHeight={{ xs: '18px', lg: '20px' }}
          fontFamily={{ xs: 'mr-eaves-xl-modern' }}
        >
          Cancela cuando quieras
        </Typography>
        <Divider style={{ borderColor: '#909090 !important' }} />
        <Typography
          marginY={'20px'}
          textAlign={'center'}
          fontSize={{ xs: '18px', lg: '20px' }}
          lineHeight={{ xs: '18px', lg: '20px' }}
          fontFamily={{ xs: 'mr-eaves-xl-modern' }}
        >
          El precio que pagas <br /> al suscribirte es de por vida
        </Typography>
        <Divider style={{ borderColor: '#909090 !important' }} />
        <Typography
          marginY={'20px'}
          textAlign={'center'}
          fontSize={{ xs: '18px', lg: '20px' }}
          lineHeight={{ xs: '18px', lg: '20px' }}
          fontFamily={{ xs: 'mr-eaves-xl-modern' }}
        >
          Atención al cliente personalizada
        </Typography>
        <Box display={'flex'} marginTop={1} justifyContent={'center'}>
          <Box
            component={'a'}
            href="https://wa.me/5215610190580"
            target="_blank"
            borderRadius={'5px'}
            paddingTop={{ xs: '14px', lg: '12px' }}
            paddingBottom={{ xs: '12px', lg: '10px' }}
            width={'220px'}
            style={{ backgroundColor: '#4dcb5b', textDecoration: 'none' }}
          >
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              fontWeight={700}
              color={'white'}
              fontSize={{ xs: '18px', sm: '22px' }}
              lineHeight={{ xs: '16px', sm: '20px' }}
              textAlign={'center'}
              paddingTop={'2px'}
            >
              CONTÁCTANOS
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
