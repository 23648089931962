import { InterestsEnum } from '../../shared/types/generated';
import { Interests } from './payment-types';

export const translateInterests = (interests: Interests[]): InterestsEnum[] => {
  const data = [];
  for (const interest of interests) {
    switch (interest) {
      case Interests.AnimalPolitico:
        data.push(InterestsEnum.AnimalPolitico);
        break;
      case Interests.AnimalGourmet:
        data.push(InterestsEnum.AnimalGourmet);
        break;
      case Interests.AnimalMx:
        data.push(InterestsEnum.AnimalMx);
        break;
      case Interests.ElSabueso:
        data.push(InterestsEnum.ElSabueso);
        break;
      case Interests.Newsweek:
        data.push(InterestsEnum.Newsweek);
        break;
    }
  }
  return data;
};

export const translateIncomeInterests = (
  interests: InterestsEnum[],
): Interests[] => {
  const data = [];
  for (const interest of interests) {
    switch (interest) {
      case InterestsEnum.AnimalPolitico:
        data.push(Interests.AnimalPolitico);
        break;
      case InterestsEnum.AnimalGourmet:
        data.push(Interests.AnimalGourmet);
        break;
      case InterestsEnum.AnimalMx:
        data.push(Interests.AnimalMx);
        break;
      case InterestsEnum.ElSabueso:
        data.push(Interests.ElSabueso);
        break;
      case InterestsEnum.Newsweek:
        data.push(Interests.Newsweek);
        break;
    }
  }
  return data;
};
