import { Certification, StudySectionEnum } from './remote-education-types';

export const cerificatesList: Certification[] = [
  {
    image: '/journalist.png',
    imageMobile: '/journalist-mobile.png',
    imageSingle: '/portada-periodismo-single.jpg',
    title: 'Periodismo y Comunicación Digital',
    description:
      '¡Mejora tus habilidades en comunicación digital! Especialízate en la creación de contenidos informativos diseñados para Internet y medios digitales.',
    price: 20000,
    slug: 'periodismo-y-comunicacion-digital',
    details: {
      descriptionLong: (
        <>
          <p style={{ paddingBottom: '15px' }}>
            Actualmente vivimos bajo un contexto donde{' '}
            <strong style={{ fontWeight: 800 }}>
              el periodismo está siendo derrotado por la propaganda
            </strong>
            , por ello, resulta fundamental prepararnos de la mejor manera para
            lograr la democratización y la rigurosidad de la información que
            llevamos a la ciudadanía.
          </p>
          Adquiere todas las herramientas necesarias para lograr este objetivo
          en nuestro Diplomado de Periodismo y Comunicación Digital.
        </>
      ),
      durationTime: '3 meses +1 de regalo | 150 horas',
      mode: 'virtual',
      entity:
        'Universidad a Distancia de Madrid, EuropaPress y Animal Político.',
      creditsOf: 'Universidad a Distancia de Madrid.',
      logos: [
        {
          href: '#',
          alt: 'logo',
          src: '/allie-udima-white.png',
          sizes: {
            desktop: [72, 41],
            mobile: [72, 41],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-europa-press-white.png',
          sizes: {
            desktop: [98, 12],
            mobile: [98, 12],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-animal.png',
          sizes: {
            desktop: [64, 22],
            mobile: [64, 22],
          },
        },
      ],
      generalObjective:
        'Que las y los alumnos se especialicen como comunicadores digitales sin perder la calidad en los contenidos adaptados a nuevos formatos mediante la adquisición de conocimientos sólidos y habilidades prácticas.',
      courseLogoSrc: '/periodismo.gif',
      glosaryType: StudySectionEnum.withoutNumbers,
      subjectGlosary: [
        {
          title: 'Módulo 1. Medios de Comunicación',
          sections: [
            'Unidad didáctica 1. Introducción a la comunicación',
            'Unidad didáctica 2. Elementos de la comunicación',
            'Unidad didáctica 3. Tipos de comunicación',
            'Unidad didáctica 4. Función de los medios de comunicación',
            'Unidad didáctica 5. Teorías y modelos de la comunicación colectiva',
          ],
        },
        {
          title: 'Módulo 2. Tipologías y Géneros del Periodismo',
          sections: [
            'Unidad didáctica 1. Introducción al periodismo',
            'Unidad didáctica 2. Tipos de periodismo',
            'Unidad didáctica 3. Géneros periodísticos',
            'Unidad didáctica 4. Prensa escrita',
            'Unidad didáctica 5. Radio',
            'Unidad didáctica 6. Televisión',
            'Unidad didáctica 7. Internet',
          ],
        },
        {
          title: 'Módulo 3. Profesión Periodística',
          sections: [
            'Unidad didáctica 1. Figura del periodista',
            'Unidad didáctica 2. Lenguaje periodístico',
            'Unidad didáctica 3. Principios y ética del periodismo',
            'Unidad didáctica 4. Código deontológico',
          ],
        },
        {
          title: 'Módulo 4. Periodismo Digital',
          sections: [
            'Unidad didáctica 1. Periodismo en la era digital',
            'Unidad didáctica 2. Nuevas competencias en el periodismo',
            'Unidad didáctica 3. Nueva figura del periodista',
            'Unidad didáctica 4. Contenido para nuevas plataformas',
            'Unidad didáctica 5. Nueva ética en internet',
          ],
        },
        {
          title: 'Módulo 5. Periodismo y Redes Sociales',
          sections: [
            'Unidad didáctica 1. Periodismo y World Wide Web',
            'Unidad didáctica 2. Periodismo en la web 4.0',
            'Unidad didáctica 3. Redes sociales como un nuevo canal de comunicación',
            'Unidad didáctica 4. Periodismo ciudadano',
            'Unidad didáctica 5. Consejos para periodistas en las redes sociales',
            'Unidad didáctica 6. Herramientas para el uso de las RR. SS.',
            'Unidad didáctica 7. Aplicaciones móviles para el periodismo digital',
          ],
        },
      ],
      extraBenefits: [
        'Tendrás acceso un mes adicional a nuestra plataforma para revisar todos los contenidos del diplomado a detalle',
        'Contarás con un tutor para recibir mínimo una sesión particular al mes',
        'Todos tus profesores cuentan con una prestigiosa carrera en su ramo',
        'Descarga en tus dispositivos toda la información, documentos y videos del curso para que puedas consultarlos en el momento que desees',
        'Recibe consulta personalizada vía WhatsApp',
        'Avanza el diplomado a tu propio ritmo',
        'Pon en práctica lo aprendido con los talleres que creamos para ti',
        'La prestigiosa Universidad Carlos III de Madrid te otorgará un título oficial al finalizar el diplomado',
      ],
    },
  },
  {
    image: '/data-analitics.png',
    imageSingle: '/data-analitics-analistics.png',
    imageMobile: '/data-analitics-mobile.png',
    title: 'Big Data and Business Analytics',
    description:
      'Aprende a tratar de manera inteligente grandes volúmenes de datos desde un punto de vista técnico y analítico.',
    price: 20000,
    slug: 'big-data-and-bussiness-analytics',
    details: {
      descriptionLong: (
        <>
          <p style={{ paddingBottom: '15px' }}>
            {' '}
            <strong style={{ fontWeight: 800 }}>
              ¿Te gusta manejar grandes volúmenes de datos e interpretarlos?
            </strong>
            , Dirigido a múltiples perfiles profesionales ―incluyendo
            periodistas de datos―, este Diplomado te brinda las herramientas
            técnicas y analíticas para analizar y extraer valiosos conocimientos
            de grandes cúmulos de información.
          </p>
        </>
      ),
      durationTime: '3 meses +1 de regalo | 150 horas',
      mode: 'virtual',
      entity: 'Universidad Pablo de Olavide, de Sevilla',
      creditsOf:
        'Instituto Iberoamericano de Movilidad Internacional, Edumes School, IBM, Universidad Pablo de Olavide y COGITI.',
      logos: [
        {
          href: '#',
          alt: 'logo',
          src: '/allie-iimi-white.png',
          sizes: {
            desktop: [116, 48],
            mobile: [97, 40],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-edumes-school-white.png',
          sizes: {
            desktop: [154, 45],
            mobile: [121, 35],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-ibm-white.png',
          sizes: {
            desktop: [81, 32],
            mobile: [81, 32],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-pablo-olavide-white.png',
          sizes: {
            desktop: [107, 40],
            mobile: [94, 35],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-cgiti-white.png',
          sizes: {
            desktop: [102, 48],
            mobile: [98, 42],
          },
        },
      ],
      generalObjective:
        'Que las y los alumnos aprendan a analizar de forma óptima grandes volúmenes de datos a través de conceptos teóricos y prácticos de tal manera que puedan implementarlos en sus respectivas áreas laborales.',
      courseLogoSrc: '/big-data.gif',
      glosaryType: StudySectionEnum.onlySubjects,
      subjectGlosary: [
        {
          title: (
            <>
              <strong style={{ fontWeight: 800 }}>Asignatura 1.</strong>{' '}
              Introducción al Big Data
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              <strong style={{ fontWeight: 800 }}>Asignatura 2.</strong>{' '}
              Entendiendo Big Data: estructura, técnica y aplicación
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              <strong style={{ fontWeight: 800 }}>Asignatura 3.</strong> Métodos
              estadísticos y Data Science
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              <strong style={{ fontWeight: 800 }}>Asignatura 4.</strong>{' '}
              Modelamiento de datos y diseño de base de datos
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              <strong style={{ fontWeight: 800 }}>Asignatura 5.</strong> Data
              Warehouse y gestión documental
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              <strong style={{ fontWeight: 800 }}>Asignatura 6.</strong>{' '}
              Virtualización y Cloud Computing
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              {' '}
              <strong style={{ fontWeight: 800 }}>Asignatura 7.</strong> Big
              Data para diferentes sectores
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              {' '}
              <strong style={{ fontWeight: 800 }}>Asignatura 8.</strong>{' '}
              Emprender su proyecto en Big Data
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              {' '}
              <strong style={{ fontWeight: 800 }}>Asignatura 9.</strong>{' '}
              Entornos virtuales de trabajo colaborativo y lenguajes y
              paradigmas de programación
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              {' '}
              <strong style={{ fontWeight: 800 }}>Asignatura 10.</strong>{' '}
              Técnicas y herramientas de protección de redes
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              {' '}
              <strong style={{ fontWeight: 800 }}>Asignatura 11.</strong>{' '}
              Técnicas de inteligencia artificial
            </>
          ),
          sections: [],
        },
        {
          title: (
            <>
              {' '}
              <strong style={{ fontWeight: 800 }}>Asignatura 12.</strong>{' '}
              Trabajo final
            </>
          ),
          sections: [],
        },
      ],
      extraBenefits: [
        'Tendrás acceso un mes adicional a nuestra plataforma para revisar todos los contenidos del diplomado a detalle',
        'Contarás con un tutor para recibir mínimo una sesión particular al mes',
        'Todos tus profesores cuentan con una prestigiosa carrera en su ramo',
        'Descarga en tus dispositivos toda la información, documentos y videos del curso para que puedas consultarlos en el momento que desees',
        'Recibe consulta personalizada vía WhatsApp',
        'Avanza el diplomado a tu propio ritmo',
        'Pon en práctica lo aprendido con los talleres que creamos para ti',
        'La prestigiosa Universidad Carlos III de Madrid te otorgará un título oficial al finalizar el diplomado',
      ],
    },
  },
  {
    image: '/human-rights.jpg',
    imageMobile: '/human-rights-mobile.jpg',
    imageSingle: '/human-rights-banner.jpg',
    title: 'Igualdad, Género y Derechos Humanos',
    description:
      'Adquiere competencias profesionales a través de una capacitación integral teórica y práctica para el desarrollo y evaluación de distintas dinámicas en materia de igualdad.',
    price: 20000,
    slug: 'igualdad-genero-y-derechos-humanos',
    details: {
      descriptionLong: (
        <>
          <p style={{ paddingBottom: '15px' }}>
            {' '}
            <strong style={{ fontWeight: 800 }}>
              ¿Consideras que la igualdad, la perspectiva de género y los
              derechos humanos son importantes para la ejecución de tu labor
              como periodista?
            </strong>{' '}
            El Instituto de Estudios de Género de la UC3M nos ofrece un
            tratamiento veraz y oportuno de estos tres temas coyunturales a
            nivel mundial para lograr una correcta y responsable praxis
            periodística.
          </p>
        </>
      ),
      durationTime: '3 meses +1 de regalo | 120 horas',
      mode: 'virtual',
      entity: 'Universidad Carlos III de Madrid',
      creditsOf:
        'Instituto Iberoamericano de Movilidad Internacional, Edumes School, IBM, Universidad Pablo de Olavide y COGITI.',
      logos: [
        {
          href: '#',
          alt: 'logo',
          src: '/allie-animal.png',
          sizes: {
            desktop: [100, 40],
            mobile: [64, 22],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-iimi-white.png',
          sizes: {
            desktop: [116, 48],
            mobile: [97, 40],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-edumes-school-white.png',
          sizes: {
            desktop: [154, 45],
            mobile: [121, 35],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-ibm-white.png',
          sizes: {
            desktop: [81, 32],
            mobile: [81, 32],
          },
        },
        {
          href: '#',
          alt: 'logo',
          src: '/allie-pablo-olavide-white.png',
          sizes: {
            desktop: [107, 40],
            mobile: [94, 35],
          },
        },
      ],
      generalObjective:
        'Que las y los alumnos adquieran un compromiso firme con la justicia social, eliminando barreras y promoviendo una convivencia equitativa entre las y los ciudadanos mediante el respeto a los derechos fundamentales de cada persona.',
      courseLogoSrc: '/derechos.gif',
      glosaryType: StudySectionEnum.withNumbers,
      subjectGlosary: [
        {
          title: '1. Teoría feminista',
          sections: [
            '1.1. Ilustración, sufragismo y modernidad',
            '1.2. Neofeminismo, feminismo liberal y feminismo radical',
            '1.3. Poscolonialismo, interseccionalidad y ciberfeminismo',
          ],
        },
        {
          title: '2. Violencia de género',
          sections: [
            '2.1. Marco conceptual. Causas y orígenes',
            '2.2. Características y tipos',
            '2.3. Pornografía. Escuela de violencia',
            '2.4. Explotación sexual y reproductiva. Prostitución y vientres de alquiler',
          ],
        },
        {
          title:
            '3. Promoción y protección internacional de la mujer en el ámbito internacional',
          sections: [
            '3.1. La protección internacional de la mujer en el trabajo: los Convenios de la OIT',
            '3.2. Los derechos humanos de las mujeres',
            '3.3. La igualdad de género en Europa y América Latina',
          ],
        },
        {
          title: '4. Igualdad laboral',
          sections: [
            '4.1. Negociación colectiva en materia de igualdad',
            '4.2. Discriminación laboral y planes de igualdad',
            '4.3. Acoso sexual vs. acoso laboral',
            '4.4. Protocolos de prevención e intervención',
          ],
        },
        {
          title: '5. Presupuestos con perspectiva de género',
          sections: [
            '5.1. Igualdad y perspectiva de género en los presupuestos',
            '5.2. Diseño, planificación, gestión y evaluación de proyectos con perspectiva de género',
            '5.3. Plan, programa y proyecto',
            '5.4. Ciclo de un proyecto',
            '5.5. Técnicas y metodologías de análisis: la Teoría de las 3R y la Teoría de las capacidades',
            '5.6. Diseño de indicadores',
          ],
        },
        {
          title: '6. Comunicación',
          sections: [
            '6.1. El sexismo lingüístico',
            '6.2. Recursos para evitar el sexismo lingüístico',
            '6.3. El género gramatical',
            '6.4. Sesgos discursivos',
          ],
        },
      ],
      extraBenefits: [
        'Tendrás acceso un mes adicional a nuestra plataforma para revisar todos los contenidos del diplomado a detalle',
        'Contarás con un tutor para recibir mínimo una sesión particular al mes',
        'Todos tus profesores cuentan con una prestigiosa carrera en su ramo',
        'Descarga en tus dispositivos toda la información, documentos y videos del curso para que puedas consultarlos en el momento que desees',
        'Recibe consulta personalizada vía WhatsApp',
        'Avanza el diplomado a tu propio ritmo',
        'Pon en práctica lo aprendido con los talleres que creamos para ti',
        'La prestigiosa Universidad Carlos III de Madrid te otorgará un título oficial al finalizar el diplomado',
      ],
    },
  },
];
