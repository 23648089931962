import { Box } from '@mui/material';

export const SectionSingleLogo: React.FC<{ src: string }> = ({ src }) => {
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box
        height={{ xs: '140px', md: '500px' }}
        width={'100%'}
        maxWidth={'1920px'}
        position={'relative'}
        component={'div'}
      >
        <Box
          component={'img'}
          width={'100%'}
          height={'100%'}
          src={src}
          style={{ objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
        />
      </Box>
    </Box>
  );
};
