import { Box, Grid } from '@mui/material';
import React from 'react';
import { cerificatesList } from '../remote-education-data';
import { CertificationCardAlt } from './CertificationCardAlt';

export const SectionCertificatesCards: React.FC<{ mainSlug?: string }> = ({
  mainSlug,
}) => {
  console.log({ mainSlug });
  const certificates = cerificatesList.filter((item) => item.slug != mainSlug);
  return (
    <Box
      display={'flex'}
      marginTop={{ xs: '15px' }}
      justifyContent={'center'}
      paddingX={{ xs: '16px', lg: '0px' }}
    >
      <Grid
        spacing={{ xs: '0px', lg: '40px' }}
        maxWidth={{ xs: '520px', lg: '1040px' }}
        justifyContent={'center'}
        container
      >
        {certificates.map((cert, index) => (
          <Grid key={'cetificate' + index} item xs={12} lg={6}>
            <CertificationCardAlt data={cert} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
