import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export const Metadata: React.FC = () => {
  const location = useLocation();
  console.log({ location });
  useLayoutEffect(() => {
    document.title = 'Hola mundo';
  }, []);
  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content={
            'Tus aportaciones contribuyen al Periodismo de Investigación.'
          }
        />
        <meta
          property="og:description"
          content={
            'Si disfrutas del periodismo de investigación como nosotros, llegaste al lugar indicado. Tu aportación ayuda a financiar periodismo independiente de calidad.'
          }
        />

        <meta
          name="title"
          content={
            'Tus aportaciones contribuyen al Periodismo de Investigación.'
          }
        />
        <meta
          name="description"
          content={
            'Si disfrutas del periodismo de investigación como nosotros, llegaste al lugar indicado. Tu aportación ayuda a financiar periodismo independiente de calidad.'
          }
        />
      </Helmet>
    </>
  );
};
