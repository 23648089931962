import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { QuestionAnswer } from './QuestionAnswer';

const frecuentQuestionsData = [
  {
    question:
      '¿Tengo que tener una suscripción de Animal Político para poder ingresar al diplomado?',
    answer:
      'No es obligatorio, sin embargo, ser suscriptor te otorga hasta 30% de descuento y además te brinda todos los beneficios de la suscripción al medio.',
  },
  {
    question: '¿Los diplomados a distancia son impartidos por Animal Político?',
    answer:
      'Cada diplomado cuenta con docentes especializados en la materia impartida; sin embargo, periodistas destacados de Animal Político imparten clases grabadas online con temáticas específicas en torno al periodismo.',
  },
  {
    question: '¿En qué horario serán las clases de los diplomados?',
    answer:
      'La modalidad de los diplomados es a distancia; cada alumno puede elegir el horario que deseé para tomar los módulos del curso. Las MasterClasses y Webinars impartidas por Animal Político serán previamente grabadas; las tutorías personalizadas impartidas por la Universidad Carlos III de Madrid se podrán agendar con anticipación de acuerdo al horario de cada estudiante.',
  },
];

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '28px !important',
      lineHeight: '28px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '28px !important',
        lineHeight: '28px !important',
      },
    },
    subTitle: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    buttomMoreInfo: {
      color: '#5A6EEC',
      fontSize: '22px !important',
      lineHeight: '22px !important',
      textDecoration: 'none !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
  }),
);

export const FrecuentQuestions: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      display={'flex'}
      style={{
        backgroundColor: '#5A6EEC',
      }}
      justifyContent={'center'}
    >
      <Box maxWidth={'1040px'}>
        <Box
          component={'div'}
          paddingX={'16px !important'}
          paddingTop={'40px'}
          paddingBottom={'20px'}
        >
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.title}
            color={'white'}
            fontWeight={800}
            paddingBottom={'30px'}
          >
            PREGUNTAS FRECUENTES
          </Typography>
          <Box>
            {frecuentQuestionsData.map((item, index) => (
              <QuestionAnswer
                key={'answer a question' + index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
