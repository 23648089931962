export type ImgSizes = {
  desktop: number[];
  mobile: number[];
};

export type LogoData = {
  href: string;
  alt: string;
  src: string;
  sizes: ImgSizes;
};

export type Certification = {
  image: string;
  imageMobile: string;
  imageSingle: string;
  title: string;
  description: string;
  price: number;
  slug: string;
  details: CertificationDetails;
};

export enum StudySectionEnum {
  withoutNumbers = 'without-number',
  onlySubjects = 'only-subjects',
  withNumbers = 'with-number',
}

export type SubjectDetails = {
  title: string | React.ReactNode;
  sections: string[];
};

export type CertificationDetails = {
  descriptionLong: React.ReactNode;
  durationTime: string;
  mode: string;
  entity: string;
  creditsOf: string;
  logos: LogoData[];
  generalObjective: string;
  courseLogoSrc: string;
  glosaryType: StudySectionEnum;
  subjectGlosary: SubjectDetails[];
  extraBenefits: string[];
};
