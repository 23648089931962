import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    text: {
      fontSize: '28px !important',
      lineHeight: '30px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px !important',
        lineHeight: '24px !important',
      },
    },
    listItem: {
      fontSize: '22px !important',
      lineHeight: '24px !important',
      paddingBottom: '15px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '18px !important',
        paddingBottom: '10px !important',
      },
    },
  }),
);
export const SectionExtraBenefits: React.FC<{ data: string[] }> = ({
  data,
}) => {
  const classes = useStyles();
  return (
    <Box
      component={'div'}
      sx={{
        background:
          'linear-gradient(white, white) padding-box, linear-gradient(180deg, #02E2CF 0%, #98FAFF 100%) border-box',
        border: '2px solid transparent',
        borderRadius: '10px', // Opcional: si quieres esquinas redondeadas
        padding: {
          xs: '20px 16px 0px 16px !important',
          lg: '20px 32px 0px 32px !important',
        },
        margin: { xs: '30px 0px', lg: '30px 0px' },
      }}
    >
      <Grid
        container
        alignItems={'center'}
        paddingBottom={{ xs: '3px', lg: '5px' }}
        borderBottom={'2px solid #77FFAA'}
      >
        <Grid item width={{ xs: '38px', lg: '60px' }}>
          <Box
            component={'img'}
            width={{ xs: '31px', lg: '40px' }}
            height={{ xs: '43px', lg: '50px' }}
            src={'/patch-icon.png'}
            style={{ objectFit: 'cover' }}
          />
        </Grid>
        <Grid item xs height={'100%'} display={'flex'} alignItems={'center'}>
          <Typography
            className={classes.text}
            fontFamily={'mr-eaves-xl-modern'}
          >
            <strong style={{ fontWeight: 800 }}>Beneficios adicionales</strong>
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <ul>
          {data.map((item, index) => (
            <li key={'item de beneficio extra' + index}>
              <Typography
                fontFamily={'mr-eaves-xl-modern'}
                className={classes.listItem}
              >
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
