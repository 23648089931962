import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { SocialBar } from './SocialBar';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: '46px 74px !important',
      paddingTop: '45px !important',
      background: '#000000',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '50px 10px !important',
      },
    },
    rootLogos: {
      padding: '16px 74px !important',
      paddingTop: '15px !important',
      background: '#F3F4F6',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '10px 10px !important',
      },
    },
    rootItems: {
      padding: '0 0px !important',
      [theme.breakpoints.down('lg')]: {
        padding: '0 0px !important',
      },
    },
  }),
);

const ItemsFooter = [
  {
    text: 'Aviso de privacidad',
    url: 'https://www.animalpolitico.com/aviso-privacidad',
  },
];

export const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box display={'flex'} justifyContent={'center'}>
        <Box width={'240px'}>
          <Grid container component={'div'}>
            <Grid item xs={12} className={classes.rootItems}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                paddingBottom={'30px'}
              >
                <Box component={'a'} href="/diplomados-a-distancia">
                  <Box
                    component={'img'}
                    src="/diplomados-oscuro.png"
                    alt="logo"
                    width={{ xs: 155, md: 200 }}
                    height={{ xs: 40, md: 50 }}
                    style={{ flexGrow: 1, objectFit: 'contain' }}
                  />
                </Box>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                paddingBottom={'30px'}
                borderBottom={'1px solid white'}
              >
                <Box component={'a'} href="https://animalpolitico.com">
                  <Box
                    component={'img'}
                    src="/logo.svg"
                    alt="logo"
                    width={{ xs: 104, md: 150 }}
                    height={{ xs: 36, md: 54 }}
                    style={{ flexGrow: 1, objectFit: 'contain' }}
                  />
                </Box>
              </Box>
              <Typography
                fontSize={{ xs: '18px', md: '22px' }}
                fontFamily={'mr-eaves-xl-modern'}
                color={'white'}
                fontWeight={800}
                textAlign={'center'}
                paddingTop={'30px'}
              >
                Siguenos en redes
              </Typography>
              <Box
                paddingTop={'30px'}
                display={'flex'}
                justifyContent={'center'}
              >
                <SocialBar />
              </Box>
            </Grid>
          </Grid>
          <Grid paddingTop={'30px'} container>
            {ItemsFooter.map((item, index) => (
              <Grid item xs={12} key={'foot-link' + index}>
                <Box
                  component={'a'}
                  sx={{ textDecoration: 'none !important' }}
                  href={item.url}
                  target="_blank"
                >
                  <Typography
                    textAlign={'center'}
                    color={'white'}
                    fontSize={{ xs: '18px', md: '22px' }}
                    fontFamily={'mr-eaves-xl-modern'}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
