import { Box, Grid, Theme, Typography } from '@mui/material';
import React from 'react';
import { Plan, PlanFrequency } from '../../../shared/types/generated';
import { createStyles, makeStyles } from '@mui/styles';
import { USPeso } from '../../plan/components/PlanNewCard';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    planName: {
      fontSize: '30px !important',
      lineHeight: '30px !important',
      fontWeight: '700 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '30px !important',
        lineHeight: '30px !important',
      },
    },
    planRealPrice: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      fontWeight: '700 !important',
      color: '#909090 !important',
      textDecoration: 'line-through',
      textDecorationThickness: '1px',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    planRealPriceUnit: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      fontWeight: '700 !important',
      color: '#909090 !important',
      textDecorationThickness: '1px',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '22px !important',
      },
    },
    planActualPrice: {
      fontSize: '30px !important',
      lineHeight: '30px !important',
      fontWeight: '800 !important',
      color: '#ff3c41',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '22px !important',
      },
    },
    planActualPriceFequency: {
      fontSize: '30px !important',
      lineHeight: '30px !important',
      fontWeight: '800 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px !important',
        lineHeight: '26px !important',
      },
    },
    planDiscount: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      color: '#FF3C41',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
  }),
);

export const PaymentPlanInfo: React.FC<{
  plan: Plan;
  priceAfterDiscount?: number;
  firstPayment?: number;
}> = ({ plan, priceAfterDiscount, firstPayment }) => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container>
        <Grid item maxWidth={{ xs: '100px', lg: '150px' }}>
          <Box
            height={{ xs: '100px', lg: '150' }}
            width={{ xs: '100px', lg: '150' }}
          >
            <img
              src={
                plan?.title?.toLowerCase().includes('estudiantil')
                  ? '/student-plan-logo.png'
                  : plan?.title?.toLowerCase().includes('estándar')
                  ? '/standard-plan-logo.png'
                  : '/pro-plan-logo.png'
              }
              alt="Logo de plan"
              width={'100%'}
              height={'100%'}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box marginLeft={{ xs: 1, sm: 2 }}>
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.planName}
            >
              Suscripción{' '}
              {plan.title_front?.toLowerCase().includes('estándar')
                ? 'mensual'
                : plan.title_front?.toLowerCase()?.includes('profesional')
                ? 'anual'
                : 'estudiantil o docente'}
            </Typography>
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.planDiscount}
              paddingBottom={2}
            >
              {'al 50% de descuento'}
            </Typography>
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              color={'#FF3C41'}
              fontWeight={800}
              fontSize={{ xs: '18px', md: '22px' }}
              lineHeight={{ xs: '16px', md: '24px' }}
            >
              Total:
            </Typography>
            <Typography
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.planRealPriceUnit}
            >
              MXN{' '}
              <Typography
                component={'span'}
                fontFamily={'mr-eaves-xl-modern'}
                className={classes.planRealPrice}
              >
                {plan?.helper_text?.replace(' MXN', '')}
                {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
              </Typography>
            </Typography>
            {firstPayment ? (
              <Typography
                component={'div'}
                fontFamily={'mr-eaves-xl-modern'}
                className={classes.planActualPriceFequency}
                sx={{
                  color: '#ff3c41 !important',
                }}
              >
                {`Pago único $${firstPayment}/el primer mes`}
              </Typography>
            ) : null}
            <Typography fontFamily={'mr-eaves-xl-modern'}>
              <Typography
                component={'span'}
                fontFamily={'mr-eaves-xl-modern'}
                className={classes.planActualPriceFequency}
                sx={{
                  textDecoration: priceAfterDiscount ? 'line-through' : 'none',
                  color: firstPayment
                    ? 'black !important'
                    : '#ff3c41 !important',
                }}
              >
                {firstPayment ? 'Después ' : ''}
                {USPeso.format(Number(plan?.price))}
                {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
              </Typography>
              {priceAfterDiscount ? (
                <Typography
                  component={'span'}
                  fontFamily={'mr-eaves-xl-modern'}
                  className={classes.planActualPriceFequency}
                  style={{
                    color: firstPayment
                      ? 'black !important'
                      : '#ff3c41 !important',
                  }}
                >
                  {' '}
                  - {USPeso.format(priceAfterDiscount)}
                  {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
                </Typography>
              ) : null}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
