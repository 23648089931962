import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { EndlessAllie } from './EndlessAllie';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '28px !important',
      lineHeight: '28px !important',
      paddingBottom: '20px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
        paddingBottom: '15px !important',
      },
    },
    subTitle: {
      fontSize: '22px !important',
      lineHeight: '22px !important',
      paddingBottom: '20px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
        paddingBottom: '15px !important',
      },
    },
    secondTitle: {
      fontSize: '28px !important',
      lineHeight: '28px !important',
      paddingBottom: '30px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
        paddingBottom: '15px !important',
      },
    },
  }),
);
export const SectionAllies: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      paddingBottom={'30px'}
      component={'div'}
      style={{ backgroundColor: '#FFF8EF' }}
    >
      <Box paddingTop={'60px'} display={'flex'} justifyContent={'center'}>
        <Box
          width={{ xs: '100%', sm: '602px' }}
          paddingX={{ xs: '20px', sm: '0px' }}
        >
          <Typography
            className={classes.title}
            fontFamily={'violeta'}
            fontWeight={700}
            textTransform={'uppercase'}
          >
            ¿Te gustaría ampliar tus conocimientos en periodismo?
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.subTitle}
          >
            Animal Político en colaboración con la Universidad Carlos III de
            Madrid ponemos a tu disposición tres diplomados de modalidad online
            para ampliar tus aptitudes en el ramo.
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.secondTitle}
            fontWeight={800}
          >
            Instituciones Aliadas:
          </Typography>
        </Box>
      </Box>
      <EndlessAllie />
    </Box>
  );
};
