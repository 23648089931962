import React, { LegacyRef, useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Theme } from '@mui/material';
import 'react-alice-carousel/lib/alice-carousel.css';
import { createStyles, makeStyles } from '@mui/styles';

type SliderProps = {
  items?: React.ReactNode[];
  infinite?: boolean;
  controls?: boolean;
  onlyOne?: boolean;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    cleanButton: {
      background: 'red!important',
      border: 'none!important',
      cursor: 'pointer',
      borderRadius: '100%!important',
      width: '24px!important',
      height: '24px',
      outline: 'none!important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
export const SliderPlanBrands: React.FC<SliderProps> = ({
  items,
  controls,
  infinite,
  onlyOne = false,
}) => {
  const sliderRef: LegacyRef<AliceCarousel> | undefined = React.createRef();
  const [responsive, setResponsive] = useState({});
  const [renderKey, setKey] = useState(0);

  useEffect(() => {
    setKey(Date.now());
  }, []);
  const classes = useStyles();
  useEffect(() => {
    setResponsive({
      0: {
        items: 2,
        itemsFit: 'contain',
      },
      300: {
        items: 4,
        itemsFit: 'contain',
      },
      720: {
        items: 6,
        itemsFit: 'contain',
      },
      1240: {
        items: 8,
        itemsFit: 'contain',
      },
    });
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'relative',
          objectFit: 'contain',
        }}
      >
        <AliceCarousel
          renderKey={renderKey}
          autoHeight={onlyOne}
          autoWidth={onlyOne}
          mouseTracking
          touchTracking
          touchMoveDefaultEvents
          items={items}
          responsive={responsive}
          infinite={infinite}
          disableButtonsControls
          ref={sliderRef}
          disableDotsControls={true}
          autoPlay
          autoPlayInterval={10}
          animationDuration={2500}
          animationEasingFunction="linear"
        />
        {controls && sliderRef && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              position: 'absolute',
              bottom: '145px',
              left: '0',
            }}
          >
            <button
              className={classes.cleanButton}
              onClick={() =>
                sliderRef && sliderRef.current && sliderRef.current.slidePrev()
              }
            >
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                <img src="/arrow-left.svg" alt="logo" width={18} height={18} />
              </div>
            </button>
            <button
              className={classes.cleanButton}
              onClick={() =>
                sliderRef && sliderRef.current && sliderRef.current.slideNext()
              }
            >
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                <img src="/arrow-right.svg" alt="logo" width={18} height={18} />
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
