import { useParams } from 'react-router-dom';
import { FrecuentQuestions } from './components/FrecuentQuestions';
import { Layout } from './components/Layout';
import { SectionAssosiatedAndRedirects } from './components/SectionAssosiatedAndRedirects';
import { SectionSingleLogo } from './components-single/SectionSingleLogo';
import { cerificatesList } from './remote-education-data';
import { SectionInfoSingle } from './components-single/SectionInfoSingle';
import { StudySections } from './components-single/StudySections';
import { SectionCertificatesCards } from './components-single/SectionCertificatesCards';

export const RemoteEducationSingle: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const mainCertificate = cerificatesList.filter(
    (item) => item.slug === slug,
  )[0];
  if (!mainCertificate) return null;
  return (
    <>
      <Layout>
        <SectionSingleLogo src={mainCertificate.imageSingle} />
        <SectionInfoSingle data={mainCertificate} />
        <StudySections
          glossary={mainCertificate.details.subjectGlosary}
          type={mainCertificate.details.glosaryType}
        />
        <SectionCertificatesCards mainSlug={slug} />
        <FrecuentQuestions />
        <SectionAssosiatedAndRedirects />
      </Layout>
    </>
  );
};
