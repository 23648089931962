import { Box, createStyles, Grid, Theme } from '@mui/material';
import { Plan } from '../../../shared/types/generated';
import { makeStyles } from '@mui/styles';
import { bennefitsDataMobile } from '../plan-utils';
import { PlanBennefitItemMobile } from './PlanBennefitItemMobile';
import { SubscribeButtonMobile } from './SubscribeButtonMobile';
import { PlanInfoTwo } from './PlanInfoTwo';
import { CarrerSection } from './CarrerSection';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    containerBox: {
      borderRadius: '20px',
      boxShadow: '4px 4px 5px 0px #0000001A',
      backgroundColor: 'white',
      overflow: 'hidden',
      padding: '40px 0px 0px 0px',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    recomendBox: {
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      backgroundColor: '#FFD93B',
      width: '200px',
      height: '30px !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
        width: '150px !important',
        height: '20px !important',
      },
    },
    recomendText: {
      textAlign: 'center',
      lineHeight: '20px !important',
      fontSize: '20px !important',
      fontWeight: '700 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    collegeBennefitContainer: {
      backgroundColor: '#E5E5E5 !important',
      padding: '12px !important',
      borderRadius: '20px',
    },
    buttonExpand: {
      width: '22px',
      height: '21px',
      transform: 'rotate(0deg)',
      transition: 'transform 0.2s ease-out',
      borderRadius: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    buttonExpandOpen: {
      width: '22px',
      height: '21px',
      transform: 'rotate(-180deg)',
      transition: 'transform 0.2s ease-out',
      borderRadius: '100%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      cursor: 'pointer',
    },
    firstTextExpandible: {
      fontSize: '24px !important',
      lineHeight: '27px !important',
      fontWeight: '800 !important',
      color: '#5A6AF9 !important',
      textAlign: 'center !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px !important',
        lineHeight: '25px !important',
      },
    },
    secondTextExpandible: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      color: '#5A6AF9 !important',
      textAlign: 'center !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
        lineHeight: '18px !important',
      },
    },
    accordionContent: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: 'max-height 0.2s ease-out',
    },
    accordionContentOpen: {
      maxHeight: '240px' /* Adjust based on your content */,
      transition: 'max-height 0.2s ease-in',
      overflow: 'hidden',
    },
    completeContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'transparent',
      transition: 'background 0.2s ease-out',
      pointerEvents: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
    },
    completeContainerOpen: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#000000aa',
      transition: 'background 0.2s ease-in',
      pointerEvents: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'end',
    },
  }),
);

export const PlanStudentMobile: React.FC<{ plan?: Plan }> = ({ plan }) => {
  const classes = useStyles();
  if (!plan) return null;
  return (
    <Box
      paddingX={{ xs: 2, lg: '19px' }}
      component={'div'}
      className={classes.containerBox}
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <PlanInfoTwo plan={plan} discount="50% de descuento" />
        </Grid>

        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'center'}>
            <SubscribeButtonMobile backgroudColor="#4052ed" plan={plan} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box marginBottom={1}>
            {bennefitsDataMobile.student.map((item, index) => (
              <PlanBennefitItemMobile
                withoutBorder={bennefitsDataMobile.student.length - 1 === index}
                key={JSON.stringify(item.text) + index}
                bennefit={item}
                paddingY={24}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
      <CarrerSection discount="20" withoutTopPadding={true} />
    </Box>
  );
};
