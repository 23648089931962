import React from 'react';
import { Box } from '@mui/material';
import { SliderAllies } from '../../../shared/components/slider/SliderAllies';

const IMAGES = [
  {
    href: '#',
    alt: 'logo',
    src: '/allie-iimi.png',
    sizes: {
      desktop: [116, 48],
      mobile: [72, 30],
    },
  },
  {
    href: '#',
    alt: 'logo',
    src: '/allie-edumes-school.png',
    sizes: {
      desktop: [166, 48],
      mobile: [103, 30],
    },
  },
  {
    href: '#',
    alt: 'logo',
    src: '/allie-europa-press.png',
    sizes: {
      desktop: [209, 24],
      mobile: [130, 15],
    },
  },
  {
    href: '#',
    alt: 'logo',
    src: '/allie-udima.png',
    sizes: {
      desktop: [112, 64],
      mobile: [70, 40],
    },
  },
  {
    href: '#',
    alt: 'logo',
    src: '/allie-ibm.png',
    sizes: {
      desktop: [80, 32],
      mobile: [50, 20],
    },
  },
  {
    href: '#',
    alt: 'logo',
    src: '/allie-pablo-olavide.png',
    sizes: {
      desktop: [129, 48],
      mobile: [80, 30],
    },
  },
  {
    href: '#',
    alt: 'logo',
    src: '/allie-cgiti.png',
    sizes: {
      desktop: [112, 48],
      mobile: [70, 30],
    },
  },
];

export const EndlessAllie: React.FC = () => {
  //const theme = useTheme();
  //const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const items = IMAGES.concat(IMAGES)
    .concat(IMAGES.slice(0, 2))
    .map((image) => (
      <a href={image.href} target="_blank">
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{ backgroundColor: 'transparent' }}
          justifyContent={'center'}
          height={{ xs: '50px', md: '80px' }}
          width={{ xs: '100%' }}
        >
          <Box
            component={'img'}
            src={image.src}
            alt={image.alt}
            width={{ xs: image.sizes.mobile[0], md: image.sizes.desktop[0] }}
            height={{ xs: image.sizes.mobile[1], md: image.sizes.desktop[1] }}
            //sizes={{xs:image.sizes.mobile.join(':'), md:image.sizes.desktop.join(':')}}
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </a>
    ));
  //<SliderPlanBrands items={items} infinite controls={false} />;
  /**<div id="infinite" className="highway-slider">
      <div className="container highway-barrier">
        <ul className="highway-lane">
          {items.map((item,index)=><li key={'car'+index} className="highway-car">{item}</li>)}
        </ul>
      </div>
    </div> */
  return <SliderAllies items={items} infinite={true} controls={false} />;
};
