import { Box } from '@mui/material';

export const Cart: React.FC = () => {
  return (
    <Box position={'relative'}>
      <Box
        component={'img'}
        src="/cart-white.png"
        alt={'Carrito'}
        width={'31px'}
        height={'30px'}
      />
    </Box>
  );
};
