import { Box, Container, Theme, Typography } from '@mui/material';
import { ButtonWPP } from './components/ButtonWpp';
import { createStyles, makeStyles } from '@mui/styles';
import { PageAssociatedPlansEndless } from '../../shared/components/layout/pagesAssociatedPlansEndless';
import { PlansBenefits } from './components/PlansBenefits';
import { NewsAttached } from './components/NewsAttached';
import { PlansSection } from './components/PlansSection';
import { ExtraBennefitsMobile } from './components/ExtraBennefitsMobile';
import { ContactUsMobile } from './components/ContactUsMobile';
import { TipsSection } from './components/TipsSection';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    banner: {
      backgroundImage: 'url("/portada_landing.gif")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    title: {
      fontSize: '35px !important',
      lineHeight: '42px !important',
      fontWeight: 'bold !important',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        lineHeight: '24px !important',
      },
    },
    subTitle: {
      fontSize: '30px !important',
      lineHeight: '30px !important',
      textAlign: 'center',
      color: '#000000',
      margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
    subTitleOne: {
      fontSize: '24px !important',
      lineHeight: '30px !important',
      textAlign: 'center',
      color: '#000000',
      //margin: '0 auto !important',
      marginTop: '10px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    subTitleTwo: {
      fontSize: '16px !important',
      lineHeight: '22px !important',
      textAlign: 'center',
      color: '#606060',
      opacity: '60%',
      //margin: '0 auto !important',
      marginTop: '15px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
        lineHeight: '12px !important',
      },
    },
    subTitleThree: {
      fontSize: '60px !important',
      lineHeight: '66px !important',
      textAlign: 'center',
      fontWeight: 'bold !important',
      //margin: '0 auto !important',
      marginTop: '15px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '30px !important',
        lineHeight: '33px !important',
        marginTop: '50px !important',
      },
    },
    subTitleFour: {
      fontSize: '50px !important',
      lineHeight: '55px !important',
      textAlign: 'center',
      fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '30px !important',
        lineHeight: '33px !important',
      },
    },
  }),
);
export const AnotherNewPlansPage: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <ButtonWPP />
      <Box component={'div'} style={{ backgroundColor: '#f5f7f9' }}>
        <Box
          height={{ xs: '300px', lg: '440px' }}
          width={'100%'}
          maxWidth={{ xs: '982px', md: '1440px' }}
          className={classes.banner}
        />
      </Box>
      <Box
        component={'div'}
        style={{ backgroundColor: '#f5f7f9' }}
        paddingBottom={{ xs: '20px', lg: '50px' }}
      >
        <Container maxWidth={'lg'}>
          <Typography fontFamily={'IvyJournal'} className={classes.title}>
            Hacer periodismo{' '}
            <Box
              component={'br'}
              sx={{ display: { sm: 'block', md: 'none' } }}
            />{' '}
            libre y de calidad
          </Typography>
          <Typography
            paddingTop={'12px'}
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.subTitle}
          >
            solo es posible con tu apoyo
          </Typography>
        </Container>
      </Box>
      <Box component={'div'} style={{ backgroundColor: '#f3f4f6' }}>
        <PageAssociatedPlansEndless />
      </Box>
      <PlansSection />
      <TipsSection />
      <PlansBenefits />
      <ExtraBennefitsMobile />
      <ContactUsMobile />
      <NewsAttached />
    </div>
  );
};
