import { Box, Grid, Theme, Typography } from '@mui/material';
import { Plan, PlanFrequency } from '../../../shared/types/generated';
import { createStyles, makeStyles } from '@mui/styles';
import { USPeso } from './PlanNewCard';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    planName: {
      fontSize: '40px !important',
      lineHeight: '40px !important',
      paddingBottom: '5px',
      [theme.breakpoints.down('md')]: {
        fontSize: '40px !important',
        lineHeight: '40px !important',
      },
    },
    planRealPrice: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      fontWeight: '700 !important',
      color: '#909090 !important',
      textDecoration: 'line-through',
      textDecorationThickness: '1px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
      },
    },
    planActualPrice: {
      fontSize: '40px !important',
      lineHeight: '40px !important',
      fontWeight: '700 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '40px !important',
        lineHeight: '40px !important',
      },
    },
    planActualPriceFequency: {
      fontSize: '24px !important',
      lineHeight: '24px !important',
      color: '#909090',
      fontWeight: '700 !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
      },
    },
    planDiscount: {
      fontSize: '20px !important',
      lineHeight: '20px !important',
      color: '#FF3C41',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '18px !important',
      },
    },
  }),
);

export const PlanInfoTwo: React.FC<{ plan: Plan; discount: string }> = ({
  plan,
  discount,
}) => {
  const classes = useStyles();
  return (
    <Grid container marginBottom={{ xs: 1, md: 2 }} display={'flex'}>
      <Grid item xs={6}>
        <Box marginRight={{ xs: 1, sm: 2 }}>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planName}
          >
            {plan.title_front?.toLowerCase().includes('estándar')
              ? 'Mensual'
              : plan.title_front?.toLowerCase()?.includes('profesional')
              ? 'Anual'
              : 'Estudiantil o docente'}
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planRealPrice}
          >
            {plan?.helper_text?.replace(' MXN', '')}
            {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
          </Typography>
          <Typography
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planActualPrice}
          >
            {USPeso.format(Number(plan?.price))}
            <Typography
              component={'span'}
              fontFamily={'mr-eaves-xl-modern'}
              className={classes.planActualPriceFequency}
            >
              {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
            </Typography>
          </Typography>
          <Typography
            component={'span'}
            fontFamily={'mr-eaves-xl-modern'}
            className={classes.planDiscount}
          >
            {discount}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} display={'flex'} justifyContent={'end'}>
        <Box height={{ xs: '100px' }} width={{ xs: '100px' }}>
          <img
            src={
              plan?.title?.toLowerCase().includes('estudiantil')
                ? '/student-plan-logo.png'
                : plan?.title?.toLowerCase().includes('estándar')
                ? '/standard-plan-logo.png'
                : '/pro-plan-logo.png'
            }
            alt="Logo de plan"
            width={'100%'}
            height={'100%'}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
