import React from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

const IMAGES = [
  {
    href: 'https://animalpolitico.com',
    alt: 'logo',
    src: '/ap-logo.svg',
    sizes: {
      desktop: [67.04, 23.48],
      mobile: [44.03, 15.65],
    },
  },
  {
    href: 'https://animalpolitico.com/verificacion-de-hechos',
    alt: 'logo',
    src: '/sabueso-logo.svg',
    sizes: {
      desktop: [85.28, 9.8],
      mobile: [58.85, 6.53],
    },
  },
  {
    href: 'https://www.animalgourmet.com',
    alt: 'logo',
    src: '/animal-gourmet-logo.svg',
    sizes: {
      desktop: [48, 48],
      mobile: [32, 32],
    },
  },
  {
    href: 'https://animalpolitico.com/tendencias',
    alt: 'logo',
    src: '/animal-logo.svg',
    sizes: {
      desktop: [73.5, 36],
      mobile: [49, 24],
    },
  },
  {
    href: 'https://newsweekespanol.com',
    alt: 'logo',
    src: '/newsweeklogo.svg',
    sizes: {
      desktop: [80.33, 16.07],
      mobile: [53.55, 10.71],
    },
  },
];

export const PageAssociatedPlans: React.FC = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      direction="row"
      spacing={2.5}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {IMAGES.map((image) => (
        <a href={image.href} target="_blank">
          <img
            src={image.src}
            alt={image.alt}
            width={isDownMd ? image.sizes.mobile[0] : image.sizes.desktop[0]}
            height={isDownMd ? image.sizes.mobile[1] : image.sizes.desktop[1]}
            sizes={
              isDownMd
                ? image.sizes.mobile.join(':')
                : image.sizes.desktop.join(':')
            }
            style={{ objectFit: 'contain' }}
          />
        </a>
      ))}
    </Stack>
  );
};
