import React from 'react';
import { Route, Routes as RoutesComponent } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';
import { AuthFacebookCallback } from './modules/auth/AuthFacebookCallback';
import { Session } from './modules/session/Session';
import { Layout } from './shared/components/layout/user/Layout';
import { AuthLogout } from './modules/auth/AuthLogout';
import { SettingAccountView } from './modules/settings/SettingAccountView';
import { LayoutAuth } from './shared/components/layout/auth/LayoutAuth';
import { useAuth } from './modules/auth/auth-hook';
import { MainLoader } from './shared/components/MainLoader';
//import { PaymentMethod } from './modules/payment/PaymentMethod';
import { RoleAuthorization } from './modules/rbac/RoleAuthorization';
import { RBACPermissions } from './modules/rbac/rbac-types';
import { Invoices } from './modules/invoice/Invoices';
import { Subscriptions } from './modules/subscriptions/Subscriptions';
import { Card } from './modules/card/Card';
import { Notifications } from './modules/notification/Notifications';
//import { ErrorView } from './modules/rbac/ErrorView';
import { SavedNotes } from './modules/savedNotes/SavedNotes';
import { Newsletters } from './modules/newsletter/Newsletters';
import { UserChangeEmail } from './modules/user/UserChangeEmail';
import { Convoy } from './modules/convoy/Convoy';
//import { Plans } from './modules/plan/Plans';
//import { NewPlansPage } from './modules/plan/NewPlansPage';
import { CouponsView } from './modules/coupons/CouponsView';
import { ErrorViewPlans } from './modules/rbac/ErrorViewPlans';
import { StudentCredentialProfile } from './modules/student-credential/StudentCredentialProfile';
import { PlansLayout } from './modules/plan/layout/PlansLayout';
import { AnotherNewPlansPage } from './modules/plan/NewPlansPageTwo';
import { PaymentMethodNew } from './modules/payment/PaymentMethodNew';
import { Metadata } from './modules/metadata/Metadata';
import { RemoteEducation } from './modules/remote-education/RemoteEducation';
import { RemoteEducationSingle } from './modules/remote-education/RemoteEducationSingle';

export const Routes: React.FC = () => {
  const { isLoading } = useAuth();

  return (
    <RoutesComponent>
      <Route
        path="/autenticacion"
        element={
          <>
            <Metadata />
            {isLoading ? (
              <MainLoader />
            ) : (
              <LayoutAuth withLogos={true}>
                <Auth />
              </LayoutAuth>
            )}
          </>
        }
      />
      <Route
        path="/logout"
        element={isLoading ? <MainLoader /> : <AuthLogout />}
      />
      <Route
        path="/change-email"
        element={isLoading ? <MainLoader /> : <UserChangeEmail />}
      />
      <Route
        path="/callback/facebook"
        element={isLoading ? <MainLoader /> : <AuthFacebookCallback />}
      />
      <Route
        path="/planes"
        element={
          <>
            <RoleAuthorization
              render={() =>
                isLoading ? (
                  <MainLoader />
                ) : (
                  <PlansLayout>
                    <AnotherNewPlansPage />
                  </PlansLayout>
                )
              }
              permission={RBACPermissions.CAN_SUBSCRIPTION}
              error={() => (
                <ErrorViewPlans text="Ya tienes una suscripción activa" />
              )}
            />
          </>
        }
      />
      <Route
        path="/diplomados-a-distancia"
        element={<>{isLoading ? <MainLoader /> : <RemoteEducation />}</>}
      />
      <Route
        path="/diplomados-a-distancia/:slug"
        element={<>{isLoading ? <MainLoader /> : <RemoteEducationSingle />}</>}
      />
      {/*<Route*/}
      {/*  path="/planes"*/}
      {/*  element={*/}
      {/*    <RoleAuthorization*/}
      {/*      render={() => (*/}
      {/*        <LayoutAuth isInvited={true}>*/}
      {/*          <NewPlansPage />*/}
      {/*        </LayoutAuth>*/}
      {/*      )}*/}
      {/*      permission={RBACPermissions.CAN_SUBSCRIPTION}*/}
      {/*      error={() => (*/}
      {/*        <ErrorViewPlans text="Ya tienes una suscripción activa" />*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  }*/}
      {/*/>*/}
      <Route
        path="*"
        element={
          <>
            <Session>
              <RoutesComponent>
                <Route
                  path="/"
                  element={
                    <>
                      {isLoading ? (
                        <MainLoader />
                      ) : (
                        <Layout>
                          <SettingAccountView />
                        </Layout>
                      )}
                    </>
                  }
                />
                <Route
                  path="/mis-notas-guardadas"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <SavedNotes />
                      </Layout>
                    )
                  }
                />
                <Route
                  path="/facturas"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <Invoices />
                      </Layout>
                    )
                  }
                />
                <Route
                  path="/newsletters"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <Newsletters />
                      </Layout>
                    )
                  }
                />
                <Route
                  path="/convoy"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <Convoy />
                      </Layout>
                    )
                  }
                />
                <Route
                  path="/suscripciones"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <Subscriptions />
                      </Layout>
                    )
                  }
                />
                <Route
                  path="/detalles-de-facturacion"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <Card />
                      </Layout>
                    )
                  }
                />
                <Route
                  path="/notificaciones"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <Notifications />
                      </Layout>
                    )
                  }
                />
                <Route
                  path="/perfil-estudiantil"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <StudentCredentialProfile />
                      </Layout>
                    )
                  }
                />
                {/**<Route
                  path="/metodos-de-pago/:slug"
                  element={
                    <Layout showSidebar={false} showNotification={false}>
                      <PaymentMethod />
                    </Layout>
                  }
                /> */}
                <Route
                  path="/metodos-de-pago/:slug"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <PlansLayout>
                        <PaymentMethodNew />
                      </PlansLayout>
                    )
                  }
                />
                <Route
                  path="/cupones"
                  element={
                    isLoading ? (
                      <MainLoader />
                    ) : (
                      <Layout>
                        <CouponsView />
                      </Layout>
                    )
                  }
                />
              </RoutesComponent>
            </Session>
          </>
        }
      />
    </RoutesComponent>
  );
};
