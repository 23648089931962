import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    subTitleOne: {
      fontSize: '24px !important',
      lineHeight: '30px !important',
      textAlign: 'center',
      color: '#000000',
      //margin: '0 auto !important',
      marginTop: '10px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px !important',
        lineHeight: '16px !important',
      },
    },
    textOne: {
      fontSize: '40px !important',
      lineHeight: '45px !important',
      textAlign: 'center',
      //margin: '0 auto !important',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px !important',
        lineHeight: '26px !important',
      },
    },
    textTwo: {
      fontSize: '60px !important',
      lineHeight: '60px !important',
      textAlign: 'center',
      //fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
        textTransform: 'uppercase',
        fontWeight: 'bold !important',
        fontStyle: 'normal',
      },
    },
    textTwoMin: {
      fontSize: '60px !important',
      lineHeight: '60px !important',
      textAlign: 'center',
      fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        lineHeight: '24px !important',
        textTransform: 'uppercase',
        fontWeight: 'bold !important',
        fontStyle: 'normal',
      },
    },
    textbeneffit: {
      fontSize: '20px !important',
      lineHeight: '24px !important',
      textAlign: 'center',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '22px !important',
        fontStyle: 'normal',
      },
    },
    textStar: {
      fontSize: '14px !important',
      lineHeight: '14px !important',
      textAlign: 'center',
      fontStyle: 'italic',
      //margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
        lineHeight: '12px !important',
        fontStyle: 'normal',
      },
    },
  }),
);
const beneffits = [
  {
    image: '/05_tote_bag.gif',
    label: (
      <span>
        Tote bag y<br />
        libro firmado*
      </span>
    ),
    special: true,
    labelSpacing: true,
    height: {
      sm: '120px',
      md: '160px',
    },
    width: {
      sm: '120px',
      md: '160px',
    },
  },
  {
    image: '/01_libre_de_anuncios.gif',
    label: (
      <span>
        Navega sin
        <br /> publicidad
      </span>
    ),
    special: false,
    labelSpacing: true,
    height: {
      sm: '120px',
      md: '160px',
    },
    width: {
      sm: '120px',
      md: '160px',
    },
  },
  {
    image: '/03_exclusivos_newsletters.gif',
    label: (
      <span>
        Exclusivos <br /> newsletterss
      </span>
    ),
    special: false,
    labelSpacing: false,
    height: {
      sm: '140px',
      md: '180px',
    },
    width: {
      sm: '140px',
      md: '180px',
    },
  },
  {
    image: '/02_acceso_anticipado.gif',
    label: (
      <span>
        Acceso anticipado <br /> a reportajes
      </span>
    ),
    special: false,
    labelSpacing: false,
    height: {
      sm: '140px',
      md: '180px',
    },
    width: {
      sm: '140px',
      md: '180px',
    },
  },

  {
    image: '/04_talleres_eventos.gif',
    label: (
      <span>
        Invitación a
        <br />
        talleres y eventos
      </span>
    ),
    special: false,
    labelSpacing: true,
    height: {
      sm: '120px',
      md: '160px',
    },
    width: {
      sm: '120px',
      md: '160px',
    },
  },

  {
    image: '/06_descuento_diplomados.gif',
    label: (
      <span>
        Descuento en
        <br />
        diplomados
      </span>
    ),
    special: false,
    labelSpacing: true,
    height: {
      sm: '120px',
      md: '160px',
    },
    width: {
      sm: '120px',
      md: '160px',
    },
  },
];
export const PlansBenefits: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      paddingTop={{ xs: '70px', lg: '' }}
      paddingBottom={{ xs: '40px', lg: '' }}
    >
      <Box display={'flex'} justifyContent={'center'}>
        <Box maxWidth={'760px'} width={'100%'}>
          <Typography
            fontFamily={{ xs: 'mr-eaves-xl-modern', md: 'mr-eaves-xl-modern' }}
            className={classes.textOne}
          >
            <Box component={'strong'} sx={{ fontWeight: { xs: 800, lg: 400 } }}>
              beneficios
            </Box>{' '}
            <Box sx={{ fontWeight: { xs: 400, lg: 800 } }} component={'strong'}>
              {' '}
              de tu plan
            </Box>
          </Typography>
          <Grid marginTop={{ xs: 2, sm: 4 }} container spacing={'20px'}>
            {beneffits.map((benefit, index) => (
              <Grid
                item
                xs={6}
                md={4}
                key={'beneffit' + index}
                height={{
                  xs: benefit.special ? '210px' : '200px',
                  md: benefit.special ? '270px' : '270px',
                }}
              >
                <Box display={'flex'} justifyContent={'center'}>
                  <Box
                    position={'relative'}
                    height={{ xs: benefit.height.sm, md: benefit.height.md }}
                    width={{ xs: benefit.width.sm, md: benefit.width.md }}
                  >
                    <img
                      src={benefit.image}
                      alt={''}
                      width={'100%'}
                      height={'100%'}
                      style={{ position: 'absolute', objectFit: 'contain' }}
                    />
                  </Box>
                </Box>
                {benefit.special && (
                  <Box
                    marginTop={{ xs: '10px', md: '15px' }}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Box
                      sx={{ backgroundColor: '#FFD93B', borderRadius: '100px' }}
                    >
                      <Typography
                        fontFamily={'mr-eaves-xl-modern'}
                        paddingX={1}
                        paddingTop={'4px'}
                        className={classes.textStar}
                      >
                        ★ PLAN ANUAL
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Typography
                  marginTop={
                    benefit.special
                      ? { xs: '5px', md: '10px' }
                      : !benefit.labelSpacing
                      ? { xs: '0px', md: '0px' }
                      : { xs: '15px', md: '20px' }
                  }
                  fontFamily={{ xs: 'mr-eaves-xl-modern' }}
                  className={classes.textbeneffit}
                >
                  {benefit.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
